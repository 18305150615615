/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Allostasis from 'allostasis-js-sdk/lib'
import { useAppSelector } from '../redux/hooks'
import axios from 'axios'
import TimeAgo from 'javascript-time-ago'

const allostasis = new Allostasis('greenia', {
  nodeURL: process.env.REACT_APP_CERAMIC_SERVER,
  env: process.env.REACT_APP_CERAMIC_ENV as 'stage' | 'production',
  infura: {
    url: 'https://ipfs.infura.io:5001/api/v0',
    projectId: process.env.REACT_APP_INFURA_PROJECT_ID ?? '',
    apiKey: process.env.REACT_APP_INFURA_API_KEY_SECRET ?? '',
  },
  model: 'platform'
})

const CeramicContext = createContext({
  allostasis,
  ceramic: allostasis.ceramic,
  composeClient: allostasis.composeClient,
  timeZone: '',
  locale: '',
  dateOption: null,
})

export const CeramicWrapper = ({ children }: any) => {
  const user = useAppSelector(state => state.user)

  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  )

  const [timeZone, setTimeZone] = useState<string>('')
  const [locale, setLocale] = useState<string>('')
  const [dateOption, setDateOption] = useState<any>(null)
  useEffect(() => {
    ;(async () => {
     await axios.get('https://ipapi.co/json/').then(res=>{
       setTimeZone(res.data.timezone)
       setLocale(res.data.country_code.toLowerCase())

       TimeAgo.addDefaultLocale({
         long: undefined,
         narrow: undefined,
         short: undefined,
         locale: res.data.country_code.toLowerCase(),
       })
       TimeAgo.addLocale({
         long: undefined,
         narrow: undefined,
         short: undefined,
         locale: res.data.country_code.toLowerCase(),
       })

       const options: any = {
         day: 'numeric',
         month: 'numeric',
         year: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         second: 'numeric',
         timeZone: res.data.timezone,
       }
       setDateOption(options)
     }).catch(error=>{
       setTimeZone('Europe/Berlin')
       setLocale('de')
       TimeAgo.addDefaultLocale({
         long: undefined,
         narrow: undefined,
         short: undefined,
         locale: 'de',
       })
       TimeAgo.addLocale({
         long: undefined,
         narrow: undefined,
         short: undefined,
         locale: 'de',
       })

       const options: any = {
         day: 'numeric',
         month: 'numeric',
         year: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         second: 'numeric',
         timeZone: 'Europe/Berlin',
       }
       setDateOption(options)
     })
    })()
  }, [])


  return (
    <CeramicContext.Provider
      value={{
        allostasis,
        ceramic: allostasis.ceramic,
        composeClient: allostasis.composeClient,
        timeZone: timeZone,
        locale: locale,
        dateOption: dateOption,
      }}
    >
      {children}
    </CeramicContext.Provider>
  )
}

export const useCeramicContext = () => useContext(CeramicContext)
