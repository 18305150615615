import styled from 'styled-components'
import { breakpoints } from '../../config/global-styles'
import { isDesktop } from '../../utils/detect-screen'
// import { isDesktop } from "../../utils/detect-screen";

type loginChildProps = {
  minHeight?: string
}

export const LoginChildSec = styled.div<loginChildProps>`
  width: 100%;
  height: 100%;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '')};
  background: #140e26;
  padding: 140px 0px 40px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  border-radius: 0px 8px 8px 0px;
  color: #fff;

  > .top-heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;

    > .title {
      font-size: 24px !important;
      font-weight: bold;
      font-weight: 400;
      color: white;
      padding: 0px 20px;
      line-height: 25px;
    }

    .subtitle {
      font-size: 16px !important;
      color: white;
      padding: 0px 30px;
      margin-top: 0px;
    }
    .Loading {
      font-size: 18px !important;
    }
  }
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    background: #140e26;
    font-size: 30px !important;
    > .top-heading {
      > .title {
        font-size: 30px !important;
        font-weight: bold;
        line-height: 45px;
        color: white;
      }

      .subtitle {
        font-size: 18px !important;
        color: white;
      }
    }
  }

  > .loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .preWelcome {
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
    flex-direction: column;

    > button {
      width: 48%;
    }

    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > button {
        height: 64px;
        color: black;
        width: 90%;
      }

      > button:nth-of-type(2) {
        margin-top: 32px;
      }
    }
  }

  > .items-box-row {
    width: 100%;
    display: flex;
    flex-direction: ${isDesktop() ? 'row' : 'column'};
    align-items: center;
    flex-wrap: ${isDesktop() ? 'wrap' : 'nowrap'};
    gap: 1rem;
    justify-content: ${isDesktop() ? 'center' : 'space-between'};
  }

  > .link-box {
    margin-top: -30px;

    > span {
      cursor: pointer;
      color: #39dbb2;
    }
  }

  > .items-box-col {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }

  > form {
    width: 100%;
    padding: 40px 0px 40px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 60px;

    > .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .items-box-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }

    > .link-box {
      margin-top: -30px;

      > span {
        cursor: pointer;
        color: #39dbb2;
      }
    }

    > .items-box-col {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
    }
  }
`
