import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Card, Page } from '../../components/structure'
import styled from 'styled-components'
import { Banner, Logo, Name, Slogan } from '../../components/account'
import 'cropperjs/dist/cropper.min.css'
import { Grid } from '@mui/material'
import {
  ControllerDaypickerField,
  ControllerEditorField,
  ControllerTextField,
} from '../../components/rhf'
import { CButton } from '../../components/mui'
import {
  Controller,
  SubmitHandler,
  useForm,
  useFormState,
} from 'react-hook-form'
import { GlobalContext, useCeramicContext } from '../../contexts'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { MyBlobToBuffer } from '../../utils/file'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { useNavigate } from 'react-router-dom'
import userPreview, {
  set as setUserPreview,
  userPreviewSlice,
} from '../../redux/slices/user-preview'
import { MySelect } from '../../components/custom/select'
import { breakpoints } from '../../config'
import { isDesktop } from '../../utils/detect-screen'
import { preview } from '../../models/platform/preview.model'
import { createPlatform, platformRequest } from '../../apis/platform.apis'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 60px;
    background: #1f163c;

    position: relative;
    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      position: static;
      .flex {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .right {
        position: absolute;
        left: 5px;
        top: 75px;
        float: right;
        vertical-align: center;
        z-index: 1;
      }
    }
    .right {
      position: absolute;
      right: 0%;
      bottom: 60px;
      z-index: 1;
    }
    > .flex {
      display: flex;
      position: relative;
    }
  }

  > .row-2 {
    position: relative;
    width: 100%;
    border-radius: 16px;
    border: 0.5px solid ${({ theme }) => theme.white80};
    top: 70px;
    padding: 15px 5px 90px 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      top: 20px;
    }
    > div > .body > p {
      font-family: Inter;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${({ theme }) => theme.white40};
      margin-bottom: 20px;
    }

    > div > .footer {
      position: absolute;
      right: 0;
      @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: unset;
        right: unset;
      }
    }
  }

  > .platform-info {
    color: rgba(255, 255, 255, 0.65);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .grid-items {
    display: grid;
    grid-template-columns: ${() => (isDesktop() ? '1fr 1fr' : '1fr')};
    grid-template-rows: ${() =>
      isDesktop() ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)'};
    gap: 5px;
    column-gap: 30px;
    align-items: stretch;
  }
`

interface Inputs {
  description: string
  abstract: string
  cover: Blob
  logo: Blob
  name: string
  slogan: string
  platformID: string
  overview: string
  phone: string
  address: string
  website: string
  companySize: string
  specialties: string
  date: Date
  projectVision: string
  projectMission: string
  email: string
  general: string
  startups: preview[]
  user: preview
}

export function RegisterPage(): ReactElement {
  const user = useAppSelector(state => state.userPreview)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    setError,
    getFieldState,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm<Inputs>({})

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [queryError, setQueryError] = useState<boolean>(false)
  const { makeAlert } = useContext(GlobalContext)

  // Initialize IPFS
  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  const [logo, setLogo] = useState<File | null>(null)

  const [banner, setBanner] = useState<File | null>(null)

  // const [ipfsCover, setIpfsCover] = useState(null)
  // const [ipfsLogo, setIpfsLogo] = useState(null)

  const handleCoverPreview = (e: File) => {
    setBanner(e)
    setValue('cover', e, { shouldValidate: true })
  }

  const handleLogoPreview = (e: File) => {
    setLogo(e)
    setValue('logo', e, { shouldValidate: true })
  }

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsSubmitting(true)
    let goOn = true

    if (!data.logo) {
      setError('logo', { message: 'Select logo' })
      goOn = false
    }
    if (!data.cover) {
      setError('cover', { message: 'Select banner' })
      goOn = false
    }
    if (!data.name) {
      setError('name', { message: 'Enter name of company' })
      goOn = false
    }
    if (!data.slogan) {
      setError('slogan', { message: 'Enter slogan of company' })
      goOn = false
    }
    if (!data.abstract) {
      setError('abstract', { message: 'Enter abstract' })
      goOn = false
    }
    if (!data.description) {
      setError('description', { message: 'Enter description' })
      goOn = false
    }
    if (!data.overview) {
      setError('overview', { message: 'Enter overview' })
      goOn = false
    }
    if (!data.address) {
      setError('address', { message: 'Enter address' })
      goOn = false
    }
    if (!data.phone) {
      setError('phone', { message: 'Enter phone' })
      goOn = false
    }
    if (!data.website) {
      setError('website', { message: 'Enter website' })
      goOn = false
    }
    if (!data.date) {
      setError('date', { message: 'Enter date' })
      goOn = false
    }
    if (!data.companySize) {
      setError('companySize', { message: 'Enter companySize' })
      goOn = false
    }
    if (!data.specialties) {
      setError('specialties', { message: 'Enter specialties' })
      goOn = false
    }

    if (!goOn) {
      setIsSubmitting(false)
      return
    }

    let bannerFile: any = null
    let logoFile: any = null

    const uploadToIPFS = (file: Blob) =>
      new Promise((resolve, reject) => {
        MyBlobToBuffer(file, async (err, buff) => {
          if (err) {
            reject(err)
          } else if (buff != null) {
            try {
              const result = await ipfs?.add(buff)
              resolve(result)
            } catch (uploadErr) {
              reject(uploadErr)
            }
          }
        })
      })

    try {
      if (data.cover) {
        bannerFile = await uploadToIPFS(data.cover)
        console.log('Banner File Hash:', bannerFile?.path)
      }

      if (data.logo) {
        logoFile = await uploadToIPFS(data.logo)
        console.log('Logo File Hash:', logoFile?.path)
      }
      const platformRequest: platformRequest = {
        name: data.name ?? user.name,
        email: data.email ?? '',
        projectVision: data.abstract ?? user.abstract,
        projectMission: data.description ?? user.description,
        slogan: data.slogan ?? user.slogan,
        overview: data.overview ?? user.overview,
        website: data.website ?? user.website,
        phone: data.phone ?? user.phone,
        address: data.address ?? user.address,
        companySize: data.companySize.toString() ?? user.companySize.toString(),
        date:
          new Date(data.date).toLocaleDateString('en-CA').replace(/\//g, '-') ??
          new Date(user.date).toLocaleDateString('en-CA').replace(/\//g, '-'),
        specialties: data.specialties ?? user.specialties,
        cover: bannerFile?.path || user.banner,
        logo: logoFile?.path || user.logo,
      }

      await createPlatform(platformRequest)
        .then(result => {
          navigate('/')
        })
        .catch(error => {
        })
    } catch (error) {
      setError('general', {
        message: 'An error occurred while processing the form',
      })
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSubmitPreview: SubmitHandler<Inputs> = async data => {
    var goOn = true

    if (
      watch('description') === '<p><br></p>' ||
      watch('description') === undefined ||
      watch('description') === ''
    ) {
      setError('description', {
        message:
          'Please ensure all required fields, indicated with (*), are filled before submitting.',
      })
      goOn = false
    }

    if (goOn) {
      dispatch(
        setUserPreview({
          abstract: watch('abstract'),
          name: watch('name'),
          slogan: watch('slogan'),
          description: watch('description'),
          overview: watch('overview'),
          website: watch('website'),
          phone: watch('phone'),
          address: watch('address'),
          companySize: watch('companySize'),
          date: watch('date'),
          specialties: watch('specialties'),
          banner: banner || user.banner,
          logo: logo || user.logo,
        })
      )
      navigate('/PreviewProfile')
    }
  }

  useEffect(() => {
    ;(async () => {
      setValue('abstract', decodeURIComponent(user?.abstract ?? ''), {
        shouldValidate: false,
      })
      setValue('description', decodeURIComponent(user?.description ?? ''), {
        shouldValidate: false,
      })
      setValue('name', user?.name ?? '', {
        shouldValidate: false,
      })
      setValue('slogan', user?.slogan ?? '', {
        shouldValidate: false,
      })
      setValue('overview', decodeURIComponent(user?.overview ?? ''), {
        shouldValidate: false,
      })
      setValue('website', user?.website ?? '', {
        shouldValidate: false,
      })
      setValue('phone', user?.phone ?? '', {
        shouldValidate: false,
      })
      setValue('date', user?.date ? new Date(user.date) : undefined, {
        shouldValidate: false,
      })

      setValue('specialties', user?.specialties ?? '', {
        shouldValidate: false,
      })
      setValue('address', user?.address ?? '', {
        shouldValidate: false,
      })
      setValue('cover', banner || user.banner, {
        shouldValidate: false,
      })
      setValue('logo', logo || user.logo, {
        shouldValidate: false,
      })
      setQueryError(false)
    })()
  }, [user, setValue, banner, logo])

  useEffect(() => {
    if (user?.companySize === '' || user?.companySize === undefined) {
      setValue('companySize', '', { shouldValidate: false })
    } else {
      setValue('companySize', user.companySize, { shouldValidate: true })
    }
  }, [user, setValue])

  const companySize = [
    {
      text: 'Company Size',
      value: 'Company Size',
    },
    {
      text: 'Fewer than 10',
      value: 'Fewer than 10',
    },
    {
      text: '10 - 50',
      value: '10 - 50',
    },
    {
      text: '51 - 250',
      value: '51 - 250',
    },
    {
      text: 'More than 250',
      value: 'More than 250',
    },
  ]

  return (
    <Page title="Personia Platform Register">
      <Box>
        <div className={'row-1'}>
          <Controller
            name={'cover'}
            control={control}
            rules={{
              required: {
                value: true,
                message:
                  'Please ensure all required fields, indicated with (*), are filled before submitting.',
              },
            }}
            render={({
              field: { onChange: fieldOnChange, value: fieldValue },
            }) => (
              <Banner
                isEditing={true}
                errors={errors.cover}
                banner={watch('cover')}
                setBanner={cover => setValue('cover', cover)}
                handleCoverPreview={handleCoverPreview}
              />
            )}
          />
          <div className={'flex'}>
            <Controller
              name={'logo'}
              control={control}
              rules={{
                required: {
                  value: true,
                  message:
                    'Please ensure all required fields, indicated with (*), are filled before submitting.',
                },
              }}
              render={({
                field: { onChange: fieldOnChange, value: fieldValue },
              }) => (
                <Logo
                  isEditing={true}
                  errors={errors.logo}
                  logo={watch('logo')}
                  setLogo={logo => setValue('logo', logo)}
                  handleLogoPreview={handleLogoPreview}
                />
              )}
            />
            <div style={isDesktop() ? null : { width: '100%' }}>
              <div
                style={
                  isDesktop()
                    ? null
                    : {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }
                }
              >
                <Name
                  isEditing={true}
                  errors={errors.name}
                  name={watch('name')}
                  setName={name => setValue('name', name)}
                  controllerInstance={control}
                  controllerName={'name'}
                  isRegisterPage={true}
                  loading={false}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                  }}
                />
              </div>
              <div
                style={
                  isDesktop()
                    ? null
                    : {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '-20px',
                      }
                }
              >
                <Slogan
                  isEditing={true}
                  errors={errors.name}
                  slogan={watch('slogan')}
                  setSlogan={slogan => setValue('slogan', slogan)}
                  controllerInstance={control}
                  controllerName={'slogan'}
                  isRegisterPage={true}
                  loading={false}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={'row-2'}>
          <Card title={'Share Your Business Journey'}>
            <p className={'startup-info'}>
              Please write about your platform’s journey. you can add videos and
              images headlines and titles . This will be the main peach of your
              business o be creative .
            </p>

            <form
              className="form"
              id="create-platform"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid item md={12} sm={12}>
                <ControllerTextField
                  controllerInstance={control}
                  controllerName="abstract"
                  rows={5}
                  multiline
                  errors={errors}
                  disabled={isSubmitting}
                  marginBottom={'5px'}
                  label={'Abstract'}
                  placeholder={'The asbtract of your platform'}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} sx={{ marginTop: '20px' }}>
                <ControllerEditorField
                  label={'Description'}
                  placeholder={'Full platform description'}
                  controllerName="description"
                  controllerInstance={control}
                  disabled={isSubmitting}
                  errors={errors}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting',
                    },
                  }}
                />
              </Grid>
            </form>
          </Card>
          <Card
            title={'Platform Information & Contact Details'}
            footer={[
              <CButton
                key={1}
                loading={isSubmitting}
                disabled={isSubmitting}
                margin={isDesktop() ? '0 15px 0 0' : '0px'}
                form={'create-platform'}
                background={'gray80'}
                backgroundHover={'gray80'}
                color={'white100'}
                type={'submit'}
                onClick={handleSubmit(handleSubmitPreview)}
                style={{
                  width: isDesktop() ? '172px' : '120px',
                  height: '45px',
                }}
              >
                Preview
              </CButton>,
              <CButton
                key={1}
                loading={isSubmitting}
                disabled={isSubmitting}
                margin={isDesktop() ? '0 15px 0 0' : '0px'}
                form={'create-platform'}
                background={'navy25'}
                backgroundHover={'navy25'}
                color={'white100'}
                type={'submit'}
                onClick={handleSubmit(onSubmit)}
                style={{
                  width: isDesktop() ? '172px' : '120px',
                  height: '45px',
                }}
              >
                Save
              </CButton>,
            ]}
          >
            <p className={'platform-info'}>
              Provide key details about your business to help others learn more
              about you. Make it easy for potential customers and partners to
              get in touch.
            </p>

            <form
              className="form"
              id="create-platform"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid item md={12} sm={12}>
                <ControllerTextField
                  controllerInstance={control}
                  controllerName="overview"
                  rows={5}
                  multiline
                  errors={errors}
                  disabled={isSubmitting}
                  marginBottom={'5px'}
                  label={'Overview'}
                  placeholder={'Overview of your platform'}
                  controllerRules={{
                    minLength: {
                      value: 10,
                      message: 'Please provide at least 10 characters',
                    },
                    maxLength: {
                      value: 1000,
                      message: 'Please edit to 1000 characters or fewer',
                    },
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} sx={{ marginTop: '20px' }}>
                <div className="grid-items">
                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="website"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Website'}
                      placeholder={'Enter your website'}
                      controllerRules={{
                        maxLength: {
                          value: '100',
                          message: 'Please edit to 100 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="phone"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Phone'}
                      placeholder={'Enter your phone number'}
                      controllerRules={{
                        minLength: {
                          value: 10,
                          message: 'Please provide at least 10 characters',
                        },
                        maxLength: {
                          value: 1000,
                          message: 'Please edit to 1000 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="address"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Address'}
                      placeholder={'Explain your address'}
                      controllerRules={{
                        maxLength: {
                          value: 400,
                          message: 'Please edit to 400 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <MySelect
                      name={'companySize'}
                      label={''}
                      options={companySize}
                      errors={errors}
                      controllerName={'companySize'}
                      controllerInstance={control}
                      controllerRules={{
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <ControllerDaypickerField
                      label={''}
                      controllerName={'date'}
                      controllerInstance={control}
                      errors={errors}
                      controllerRules={{
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="specialties"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Specialties'}
                      placeholder={'Explain your business here'}
                      controllerRules={{
                        maxLength: {
                          value: 500,
                          message: 'Please edit to 500 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </form>
          </Card>
        </div>
      </Box>
    </Page>
  )
}
