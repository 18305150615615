import React from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model'
import { registerPlatformJourney } from '../../../models/platform/registerPlatformInfo'
import { preview } from '../../../models/platform/preview.model'

const NameStyle = styled.div<{ textcolor: string }>`
  display: flex;
  > p {
    font-size: 20px;
    font-weight: 600;
    font-family: Inter;
    color: ${({ theme, textcolor }) => theme[textcolor]};
    line-height: 28px;
    width: 100%;
  }

  > .icon {
    position: relative;
    left: 10px;
    cursor: pointer;
  }
`

export interface Props {
  platform: registerPlatformJourney | preview
}

export function Name(props: Props) {
  const { platform } = props

  return (
    <>
      <NameStyle textcolor={'white100'}>
        <p>{platform.name}</p>
      </NameStyle>
    </>
  )
}
