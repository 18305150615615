import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { registerPlatformJourney } from '../../models/platform/registerPlatformInfo'
import { preview } from '../../models/platform/preview.model'

const initialState: preview = {
  abstract: 'sgvfd',
  description: 'fdgdfg'
}

export const updateJourneySlice = createSlice({
  name: 'updateJourney',
  initialState,
  reducers: {
    set: (state: any, action: PayloadAction<preview>) => {
      return { ...action.payload }
    },
  },
})

export const { set } = updateJourneySlice.actions

export default updateJourneySlice.reducer
