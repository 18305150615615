import { useAppSelector } from '../../redux/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
// import { ProfileBox } from '../../components/profile'
import styled from 'styled-components'
import { NothingFound } from '../../components/custom'
import { PlatformBox } from '../../components/platform'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'
import { ProfileBox } from '../../components/profile/Box'

const Box = styled.div`
  padding: 16px;
  background: ${props => props.theme.navy80};
  border-radius: 16px;
`
const getPlatformProfiles = ({ cursor, numberPerPage }: { cursor: string, numberPerPage: number }): { users: Array<PlatformProfileModel>, cursor: string } => {
  return {
    users: [
      {  id: '1',
        name: 'maryam',
        email: 'maryam_parvaz@yahoo.com',
        phoneNumber: '(307) 555-0133',
        address: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
        cover: 'https://www.sarasoueidan.com/assets/images/hr.png',
        logo: 'https://onlinetools.com/images/examples-onlineimagetools/brown-cat-sitting-on-shelf-horizontal.jpg',
        projectMission: 'hi',
        website: 'https://embodia.io/',
        projectVision: 'hello',
        about:'Gorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.',
        companySize:'50- 200',
        specialties:'Virtual reality',
        slogan:'Slogan of the Platform '
       }
    ],
    cursor: 'nextCursorValue'
  };
};
export function PlatformsPage(): ReactElement {


  const user = useAppSelector(state => state.user)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const [platforms, setPlatforms] = useState<Array<PlatformProfileModel>>([])
  const [cursor, setCursor] = useState<string>('')
  const [platformLoading, setPlatformLoading] = useState<boolean>(false)

  useEffect(() => {
    setPlatformLoading(true);
    const result = getPlatformProfiles({ cursor: '', numberPerPage: 30 });
    setPlatforms(result.users);
    setCursor(result.cursor);
    setPlatformLoading(false);
  }, []);

  // useEffect(() =>{
  //   setPlatforms()
  //   .then(result =>{
  //     setPlatforms(result.users)
  //     setCursor(result.cursor)
  //     setPlatformLoading(false)
  //   })
  //   .catch(error =>{
  //     setPlatformLoading(false)
  //   })
  // },[])
  // useEffect(() => {
  //
  //   setPlatformLoading(true)
  //   allostasisPlatform
  //     .getPlatformProfiles({ cursor: '', numberPerPage: 30 })
  //     .then(result => {
        // setPlatforms(result.users)
        // setCursor(result.cursor)
        // setPlatformLoading(false)
  //     })
  //     .catch(error => {
  //       setPlatformLoading(false)
  //     })
  // }, [])

  return (
    <Page
      title="Platforms"
      sidebar={<ProfileBox />}
      sidebar2={
        <>
          {/*{user.did !== '' ? (*/}
          {/*  <UsersBox*/}
          {/*    background={'navy90'}*/}
          {/*    users={users}*/}
          {/*    loading={usersLoading}*/}
          {/*    setLoading={loading => {*/}
          {/*      //setUsersLoading(loading);*/}
          {/*    }}*/}
          {/*    title={'People You May Know'}*/}
          {/*  />*/}
          {/*) : null}*/}
        </>
      }
    >
      <Box>
        {platformLoading ? (
          [1, 2, 3, 4].map(i => (
            <PlatformBox loading={platformLoading} key={i} />
          ))
        ) : platforms.length === 0 ? (
          <NothingFound
            icon="hourglass_disabled"
            title="No Platform Found"
            padding={'30px'}
          />
        ) : (
          <div>
            {[...platforms.filter(item => item.name !== '')]
              .sort(
                (x: any, y: any) =>
                  new Date(y.createdAt).getTime() -
                  new Date(x.createdAt).getTime()
              )
              .map((platform, i) => {
                return (
                  <PlatformBox
                    loading={platformLoading}
                    platform={platform}
                    key={i}
                  />
                )
              })}
          </div>
        )}
      </Box>
    </Page>
  )
}
