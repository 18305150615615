import { ProfilePage } from '../pages/profile'

export const ROUTES = {
    INDEX: '/',
    NOT_FOUND: '/404',
    AUTH_LOGIN_PAGE: '/auth/login',
    AUTH_REGISTER_PAGE: '/auth/register',
    ARTICLES_NEW: '/articles/new',
    ARTICLES_DETAIL: '/articles/get/:id',
    ARTICLES_EDIT: '/articles/edit/:id',
    POSTS_NEW: '/posts/new',
    POSTS_DETAILS: '/posts/get/:id',
    POSTS_EDIT: '/posts/edit/:id',
    ARTICLES_SEARCH: '/articles/search/:tag',
    POSTS_SEARCH: '/posts/search/:tag',
    ProfilePage: '/profile',
    PreviewProfile: '/PreviewProfile',
    UPDATE_ABOUT: '/UpdateAbout',
}
