import { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useAppSelector } from '../../redux/hooks';

const Select = styled.div<{ 'data-name'?: string }>`
  margin-bottom: 5px;

  > label {
    font-size: 14px;
    font-weight: bold;
    display: block;
    /* margin-bottom: 5px; */
  }

  > select {
    width: 100%;
    outline: none;
    border: 1px solid #cdcdcd;
    height: 50px;
    border-radius: 8px;
    padding: 0 10px;
    background: rgba(255, 255, 255, 0.05);
    color: #808080;
    > option {
      color: #808080;
      background: #2b263c;
    }
  }
`

interface Props {
  name: string;
  label: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  value?: any;
  options: { value: string | number; text: string }[];
  errors?: any;
  controllerInstance?: any;
  controllerRules?: any;
  controllerName?: string;
}

export function MySelect(props: Props): ReactElement {
  const {
    name,
    label,
    onChange,
    disabled = false,
    options = [],
    value,
    errors,
    controllerName,
    controllerInstance,
    // control,
    controllerRules,
  } = props

  const { register, setValue } = useForm()

  const user = useAppSelector(state => state.userPreview)


  const [companySize, setCompanySize] = useState(user?.companySize || '')

  useEffect(() => {
    // Retrieve the saved company size if returning to form
    if (user?.companySize) {
      setCompanySize(user.companySize)
    }
  }, [user])


  return (
    <>
      <Controller
        name={controllerName}
        control={controllerInstance}
        rules={controllerRules}
        render={({ field: { onChange: fieldOnChange, value: fieldValue } }) => (
          <Select data-name={name}>
            {label === '' ? null : <label>{label}</label>}
            <select
              {...register(name)}
              disabled={disabled}
              name={name}
              onChange={e => {
                const selectedValue = e.target.value
                setCompanySize(selectedValue)
                fieldOnChange(selectedValue)
                if (onChange) {
                  onChange(selectedValue)
                }
              }}
              value={companySize || fieldValue || ''}
              style={{
                border: errors[controllerName]
                  ? '1px solid #e84749'
                  : '1px solid gray',
              }}
            >
              {options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </Select>
        )}
      />
      {errors[controllerName] && (
        <span style={{ marginTop: '5px', display: 'flex' }} className="error">
          {errors[controllerName]?.message}
        </span>
      )}
    </>
  )
}