import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model'
import { registerPlatformJourney } from '../../../models/platform/registerPlatformInfo'
import { preview } from '../../../models/platform/preview.model'
import { breakpoints } from '../../../config'

const SloganStyle = styled.div<{ textcolor: string }>`
  display: flex;
 @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
width: 100%;
justify-content: center;
 }
  > p {
    font-size: 16px;
    font-weight: 400;
    font-family: Inter;
    color: ${({ theme, textcolor }) => theme[textcolor]};
    line-height: 24px;
    margin-bottom: 20px;
  }

  > .icon {
    position: relative;
    left: 10px;
    cursor: pointer;
  }
`

export interface Props {
  platform: registerPlatformJourney | preview
}

export function Slogan(props: Props) {
  const { platform } = props

  return (
    <>
      <SloganStyle textcolor={'white100'}>
        <p>{platform.slogan}</p>
      </SloganStyle>
    </>
  )
}
