import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/svg/logtypo-02.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CButton, CIconButton, CMenu, CMenuItem } from '../mui'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useDisconnect } from '@web3modal/ethers/react'
import { useCeramicContext } from '../../contexts'
import { logout } from '../../redux/slices/user'
// import { PlatformInfo } from '../../pages/profile/platformInfo'
import { ProfilePage } from '../../pages/profile'
import { PlatformsPage } from '../../pages/platforms'
import { search } from '../../apis/search.api'
import { PostModel } from '../../models/post.model'
import { ArticleModel } from '../../models/article.model'
import { ProfileModel } from '../../models/profile.model'
import { useStopwatch } from 'react-timer-hook'
import { v4 as uuid } from 'uuid'
import { logout as logoutUser } from '../../redux/slices/user'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'
const breakpoints = {
  mobile: '320px',
  tablet: '1130px',
  desktop: '1024px',
}

const PageStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > nav.menu {
    width: 100%;
    margin: 0 auto;
    height: 60px;
    background: ${props => props.theme.navy100};
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.1);

    @media (max-width: ${breakpoints.tablet}) {
      width: 100%;
      /* margin: 0 auto; */
      height: auto;
      /* flex-direction: column; */
      background: ${props => props.theme.navy100};
      position: sticky;
      /* top: 0;
                  left: 0;
                  right: 0; */
      /* z-index: 100; */
      /* box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.1); */
    }

    > .wrapper {
      max-width: 1440px;
      width: 95%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      @media (max-width: ${breakpoints.tablet}) {
        width: 95%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      > .icon {
        > svg {
          width: 150px;
        }
      }

      > .links {
        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            list-style: none;
            margin-right: 32px;

            a {
              color: ${props => props.theme.white100};
              text-decoration: none;
            }

            &.active {
              a {
                color: ${props => props.theme.green100};
                border-bottom: 2px solid ${props => props.theme.green100};
              }
            }
          }
        }
      }

      > .items {
        display: grid;
        grid-template-columns: repeat(5, auto);
        gap: 15px;

        @media (max-width: ${breakpoints.tablet}) {
          display: flex;
          flex-direction: row;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          margin-right: 20px;
        }

        > .icon {
          > svg {
            width: 150px;
          }
        }

        > a {
          font-size: 16px;
          font-weight: 500;
          color: ${props => props.theme.black80};
          text-decoration: none;
          display: flex;
          flex-direction: row;
          align-items: center;

          &:hover {
            color: ${props => props.theme.blue100};
          }
        }

        .balance {
          border: 1px solid ${props => props.theme.green100};
          display: flex;
          padding: 8px;
          border-radius: 8px;
          color: ${props => props.theme.green100};
        }
      }
    }
  }

  > .top-content {
    width: 95%;
    max-width: 1440px;
    margin: 15px auto;
  }

  > .content {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1440px;
    margin: 15px auto;
    width: 95%;
    gap: 15px;
    height: 90vh;
    overflow: hidden;
    @media (max-width: ${breakpoints.tablet}) {
      display: flex;
      flex-direction: column;
      margin: 10px;
      width: 90%;
      justify-content: center; /* Centers items horizontally in flex */
      align-items: center;
      overflow: visible;
    }

    & > ::-webkit-scrollbar {
      background: ${props => props.theme.gray70};
      width: 1px;
      margin-left: 10px;
      display: none !important;
      @media (max-width: ${breakpoints.tablet}) {
        /* display: flex; */
      }
    }

    &.with-sidebar {
      grid-template-columns: 350px 1fr;
      height: 90vh;
      overflow: auto;
      @media (max-width: ${breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        /* margin: 10px; */
        overflow: visible;
      }

      &.with-sidebar-2 {
        grid-template-columns: 350px 1fr 350px;
        height: 90vh;
        overflow: auto;
        @media (max-width: ${breakpoints.tablet}) {
          display: flex;
          flex-direction: column;
          /* margin: 20px; */
          /* width: 500px; */
          overflow: visible;
        }
      }
    }

    > .sidebar {
      height: 90vh;
      overflow: auto;
      @media (max-width: ${breakpoints.tablet}) {
        overflow: visible;
      }

      .back {
        margin-left: 75%;
        margin-bottom: 30px;
        border-bottom: 1px solid ${props => props.theme.white100};
      }
    }

    > .sidebar-2 {
      height: 90vh;
      overflow: auto;
      @media (max-width: ${breakpoints.tablet}) {
        overflow: visible;
      }
    }

    > .main {
      width: 100%;
      height: 90vh;
      overflow: auto;
      @media (max-width: ${breakpoints.tablet}) {
        overflow: visible;
      }

      @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
        width: 100%;
      }
    }
  }

  > .footer {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: ${props => props.theme.black80};

    > p {
      font-size: 14px;
      font-weight: 400;
      color: ${props => props.theme.white100};
      text-align: center;
      margin: 0;
    }
  }
`

interface Props {
  title: string
  sidebar?: ReactNode
  sidebar2?: ReactNode
  children: ReactNode
  topChildren?: ReactNode
}

export function Page(props: Props): ReactElement {
  const { title, sidebar, sidebar2, children, topChildren } = props
  const user = useAppSelector(state => state.user)
  const navigate = useNavigate()
  const location = useLocation()
  const [menuSidebar, setMenuSidebar] = useState<boolean>(false)
  const [selectSearch, setSelectSearch] = useState<boolean>(false)
  const [, setPage] = useState<string>('1')
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<Array<PostModel>>([])
  const [articles, setArticles] = useState<Array<ArticleModel>>([])
  const [users, setUsers] = useState<Array<PlatformProfileModel>>([])

  useEffect(() => {
    document.title = title
    return () => {
      document.title = ''
    }
  }, [title])

  const [openLeaveModal, setOpenLeaveModal] = useState<boolean>(false)
  const handleLeaveRoom = () => {
    setOpenLeaveModal(!openLeaveModal)
  }

  const { seconds, minutes, hours } = useStopwatch({ autoStart: true })

  const [moreCreateEl, setMoreCreateEl] = useState(null)
  const handleMoreCreate = (event: any): void => {
    event.stopPropagation()
    setMoreCreateEl(event.currentTarget)
  }
  const handleMoreClose = (e: Event): void => {
    e.stopPropagation()
    setMoreCreateEl(null)
  }
  const moreOpen = Boolean(moreCreateEl != null)

  const createNewPost = (): void => {
    setMoreCreateEl(null)
  }

  const createNewArticle = (): void => {
    setMoreCreateEl(null)
  }

  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false)

  const [openModal, setOpenModal] = useState(false)

  const dispatch = useAppDispatch()
  const handleLogout = () => {
    localStorage.removeItem('token')
    setMenuSidebar(false)
    dispatch(logoutUser())
    navigate('/auth/login')
  }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuSidebar(false)
          setValueSearch('')
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  const { pathname } = useLocation()
  const [valueSearch, setValueSearch] = useState<string>('')

  function useOutsideAlerterSearch(ref: any) {
    useEffect(() => {
      function handleClickOutsideSearch(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPosts([])
          setUsers([])
          setArticles([])
          setIsOpenSearch(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutsideSearch)
      return () => {
        document.removeEventListener('mousedown', handleClickOutsideSearch)
      }
    }, [ref])
  }

  const wrapperRefSearch = useRef(null)
  useOutsideAlerterSearch(wrapperRefSearch)

  const handleSearch = (text: string) => {
    setLoading(true)
    setValueSearch(text)
    setIsOpenSearch(true)

    setUsers([])
    setPosts([])
    setArticles([])

    if (text !== '') {
      search(text)
        .then(result => {
          setPosts(result.data.posts)
          setArticles(result.data.articles)
          setUsers(result.data.users)

          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
        })
    } else setLoading(false)
  }

  return (
    <PageStyle>
      <nav className={'menu'}>
        <div className={'wrapper'}>
          <div className={'items'} style={{ alignItems: 'center' }}>
            <div className={'icon'} style={{ width: '120px' }}>
              <Link to={'/'}>
                <Logo />
              </Link>
            </div>
          </div>
          {user.did !== '' && (
            <>
              <div className={'links'}>
                <ul>
                  <li
                    className={
                      !window.location.href.includes('articles') ? 'active' : ''
                    }
                  >
                    <Link to={'https://stage.personia.io'} target={'_blank'}>
                      Home
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={'items'}>
                <CButton
                  size={'s'}
                  background={'green100'}
                  variant={'outlined'}
                  backgroundHover={'transparent'}
                  color={'green100'}
                  startIcon={'logout'}
                  onClick={handleLogout}
                  loading={loading}
                  disabled={loading}
                >
                  <span style={{ marginLeft: '5px' }}>Logout</span>
                </CButton>

                <Link to={'/profile'}>
                  <CIconButton
                    backgroundColorHover={'transparent'}
                    backgroundColor={'transparent'}
                    customColorHover={'white100'}
                    customColor={'white100'}
                    icon={'person'}
                  />
                </Link>
              </div>
            </>
          )}
        </div>
      </nav>

      {topChildren != null ? (
        <div className={'top-content'}>{topChildren}</div>
      ) : null}

      <div
        className={`content ${sidebar != null ? 'with-sidebar' : ''} ${
          sidebar2 != null ? 'with-sidebar-2' : ''
        }`}
      >
        {sidebar != null ? <div className="sidebar">{sidebar}</div> : null}

        <div className="main">{children}</div>

        {sidebar2 != null ? <div className="sidebar-2">{sidebar2}</div> : null}
      </div>
      <CMenu
        open={moreOpen}
        left={'69'}
        id="options-menu"
        anchorEl={moreCreateEl}
        onClose={handleMoreClose}
        anchorOriginHorizontal="left"
        transformOriginHorizontal="left"
        anchorOriginVertical="bottom"
        transformOriginVertical="top"
      >
        <Link to={'/articles/new'}>
          <CMenuItem color={'white100'} onClick={createNewArticle}>
            New Article
          </CMenuItem>
        </Link>

        <Link to={'/posts/new'}>
          <CMenuItem color={'white100'} onClick={createNewPost}>
            New Post
          </CMenuItem>
        </Link>
      </CMenu>
    </PageStyle>
  )
}
