import { useEffect, useState, type ReactElement } from 'react'
import { Controller } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useAppSelector } from '../../redux/hooks'
import styled from 'styled-components'
import { platformAccount } from '../../apis/platform.apis'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'

const DatePickerWrapper = styled.div<DatePickerWrapperProps>`
  width: 100%;
  /* margin-bottom: 8px; */

  p {
    color: ${props => props.theme.white100};
  }

  input {
    color: ${props => props.theme.white100} !important;
    padding: 13px;
  }

  button {
    color: ${props => props.theme.gray60};
  }

  .MuiOutlinedInput-root {
    color: ${props => props.theme.white100};
    background: ${props => props.theme.gray70};
    border: 1px solid
      ${props => (props.hasError ? props.theme.red100 : props.theme.white30)};
    border-radius: 8px;
    width: 100%;
    flex-direction: row-reverse;

    &:hover {
      border: 1px solid
        ${props => (props.hasError ? props.theme.red100 : props.theme.black80)};
    }
  }

  div:first-child {
    div:first-child {
      width: 100%;
    }
  }
`

interface DatePickerWrapperProps {
  hasError?: boolean
}

interface Props {
  controllerName: string
  controllerInstance: any
  controllerRules?: any
  label: string
  errors: any
}

export function ControllerDaypickerField(props: Props): ReactElement {
  const { label, controllerName, controllerInstance, controllerRules, errors } =
    props

  const [platformData, setPlatformData] = useState<PlatformProfileModel>()

  useEffect(() => {
    platformAccount()
      .then(res => {
        if (res.data.account) {
          setPlatformData(res.data.account)
        }
      })
      .catch(error => {
      })
  }, [])

  return (
    <div
      className={'costumdatepicker'}
      style={{
        marginBottom: '10px',
      }}
    >
      <Controller
        name={controllerName}
        control={controllerInstance}
        rules={controllerRules}
        render={({ field: { onChange, value } }) => {
          const hasError = !!errors[controllerName]
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DemoItem label={label}>
                  <DatePickerWrapper hasError={hasError}>
                    <DatePicker
                      value={
                        platformData?.fundingStartDate
                          ? dayjs(platformData.fundingStartDate)
                          : null
                      }
                      className={'costumdatepicker'}
                      onChange={val => {
                        onChange(
                          val
                            ? `${val?.toDate()?.getFullYear()}-${(
                                val?.toDate()?.getMonth() + 1
                              )
                                ?.toString()
                                ?.padStart(2, '0')}-${val
                                .toDate()
                                ?.getDate()
                                .toString()
                                .padStart(2, '0')}`
                            : null
                        )
                      }}
                      slotProps={{
                        textField: {
                          error: hasError,
                          fullWidth: true,
                        },
                      }}
                    />
                  </DatePickerWrapper>
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          )
        }}
      />
      <span className={'error'} style={{ marginTop: '5px', display: 'flex' }}>
        {errors[controllerName]?.message}
      </span>
    </div>
  )
}
