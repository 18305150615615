import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Card, Page } from '../../components/structure'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Banner, Logo, Name, Slogan } from '../../components/account'
import { CButton, CIconButton, CTab, CTabs } from '../../components/mui'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as Edit } from '../../assets/svg/edit.svg'
import { ReactComponent as Save } from '../../assets/svg/save.svg'
import { MyBlobToBuffer } from '../../utils/file'
import { set } from '../../redux/slices/user'
import { GlobalContext, useCeramicContext } from '../../contexts'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { ArticleComment, Post } from 'allostasis-js-sdk/lib/types/allostasis'
import { PlatformArticle } from 'allostasis-js-sdk/lib/types/platform'
import { NothingFound } from '../../components/custom'
import { PostBox } from '../../components/post/box'
import { ArticleBox } from '../../components/article/box'
import '../../assets/editor.css'
import { useSelector } from 'react-redux'
import { preview } from '../../models/platform/preview.model'
import { SatelliteAlt } from '@mui/icons-material'
import {
  platformAccount,
  platformRequest,
  updatePlatform,
} from '../../apis/platform.apis'
import { findAllPosts } from '../../apis/post.apis'
import { PostModel } from '../../models/post.model'
import { findAllArticles } from '../../apis/article.apis'
import { ArticleModel } from '../../models/article.model'
import { ProfileModel } from '../../models/profile.model'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'

import { isDesktop, isMobile } from '../../utils/detect-screen'
import { IconButton, Skeleton } from '@mui/material'
import { breakpoints } from '../../config'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 60px;
    background: #1f163c;
    position: relative;
    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      position: static;
      .flex {
        width: 100% !important;
        flex-wrap: wrap;
      }
    }

    .right {
      position: absolute;
      right: 1%;
      bottom: 110px;
      z-index: 1;
    }

    > .flex {
      display: flex;
      position: relative;
    }
  }

  > .row-2 {
    margin-top: 75px;
    margin-bottom: 20px;
    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      margin-top: 0px;
    }

    > .body {
      padding: 20px;
      background: ${props => props.theme.navy80};

      > .text {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 22px;

        > .right {
          /* position: absolute;
          right: 0;
          top: -70px; */
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          > div:first-of-type {
            > span {
              font-size: 38px;
              font-weight: 600;
              line-height: 30px;
              font-style: normal;
              display: block;
              align-items: center;
              text-transform: capitalize;
              white-space: normal;

              @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
                font-size: 20px;
                width: 100%;
                word-wrap: break-word;
              }
            }
          }
          > a {
            justify-content: flex-end;
          }

          > a > button > svg {
            fill: ${props => props.theme.black100};
          }
        }

        > div:last-child {
          position: relative;
        }

        > .editor {
          > div:first-of-type {
            > span {
              font-size: 38px;
              font-weight: 600;
              line-height: 46px;
              font-style: normal;
            }
          }
        }

        p,
        span,
        div {
          color: ${props => props.theme.white100};
        }

        > h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        li,
        ol {
          color: ${props => props.theme.white100};
        }

        > a {
          color: ${props => props.theme.green100};
        }

        > img {
          margin-top: 20px;
        }
        > div:nth-of-type(1) {
          font-size: 38px;
          font-weight: 600;
          line-height: 46px;
          font-style: normal;
        }
        > p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        > div {
          > * {
            display: flex;
            height: auto;
            width: 100%;
            align-self: stretch;
            margin: -0.1rem 0;
          }
          > span:nth-of-type(1) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
          > a {
            color: #1677ff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
          > span:nth-of-type(2) {
            color: rgba(255, 255, 255, 0.65);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
`

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
  background: ${props => props.theme.navy80};
  border-radius: 16px;

  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

interface Inputs {
  banner: Blob
  logo: Blob
  name: string
  slogan: string
}

export function HomePage(): ReactElement {
  const navigate = useNavigate()
  setTimeout(() => {
    if (localStorage.getItem('wallet_id')) {
      localStorage.setItem('@w3m/wallet_id', localStorage.getItem('wallet_id'))
    }
  }, 9000)

  const {
    control,
    handleSubmit,
    setError,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    defaultValues: {},
  })

  const [platforms, setPlatforms] = useState<PlatformProfileModel>()

  useEffect(() => {
    setLoading(true)

    platformAccount()
      .then(res => {
        setLoading(false)
        if (res.data.account) {
          setPlatforms(res.data.account)
        }
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
  }, [])

  useEffect(() => {
    setValue('name', platforms?.name)
    setValue('slogan', platforms?.slogan)
  }, [setValue, platforms])

  const [banner, setBanner] = useState(null)
  const [logo, setLogo] = useState(null)

  const handleCoverPreview = (e: File) => {
    setBanner(e)
  }

  const handleLogoPreview = (e: File) => {
    setLogo(e)
  }

  const { makeAlert } = useContext(GlobalContext)

  // Initialize IPFS
  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }
  const dispatch = useAppDispatch()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)
  const [bannerFile, setBannerFile] = useState(null)
  const [logoFile, setLogoFile] = useState(null)

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsSubmitting(true)
    var goOn = true

    if (!data.name) {
      setError('name', { message: 'Enter name of company' })
      goOn = false
    }
    if (!data.slogan) {
      setError('slogan', { message: 'Enter slogan of company' })
      goOn = false
    }

    if (!goOn) {
      setIsSubmitting(false)
      return
    }

    let bannerFile: any = null
    let logoFile: any = null

    const uploadToIPFS = (file: Blob) =>
      new Promise((resolve, reject) => {
        MyBlobToBuffer(file, async (err, buff) => {
          if (err) {
            reject(err)
          } else if (buff != null) {
            try {
              const result = await ipfs?.add(buff)
              resolve(result)
            } catch (uploadErr) {
              reject(uploadErr)
            }
          }
        })
      })

    try {
      if (data.banner) {
        bannerFile = await uploadToIPFS(data.banner)
      } else {
        bannerFile = { path: platforms?.cover }
      }
      if (data.logo) {
        logoFile = await uploadToIPFS(data.logo)
      } else {
        logoFile = { path: platforms?.logo }
      }
      const platformRequest: platformRequest = {
        name: data.name,
        slogan: data.slogan,
        cover: bannerFile?.path,
        logo: logoFile?.path,
        email: '',
        projectVision: platforms.projectVision,
        projectMission: platforms.projectMission,
        overview: platforms.overview,
        website: platforms.website,
        phone: platforms.phoneNumber,
        address: platforms.address,
        companySize: platforms.companySize,
        date: new Date(platforms.fundingStartDate)
          .toLocaleDateString('en-CA')
          .replace(/\//g, '-'),
        specialties: platforms.specialties,
      }

      await updatePlatform(platformRequest)
        .then(result => {
          navigate('/')
          setEditing(false)
        })
        .catch(error => {
        })
    } catch (error) {
      //@ts-ignore
      setError('general', {
        message: 'An error occurred while processing the form',
      })
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const [tab, setTab] = useState<'posts' | 'articles' | 'journey' | 'about'>(
    'journey'
  )
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  const [noMoreData, setNoMoreDate] = useState<boolean>(false)
  const [posts, setPosts] = useState<Array<PostModel>>([])
  const [cursor, setCursor] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)
  const [articles, setArticles] = useState<Array<ArticleModel>>([])
  const [cursorArticle, setCursorArticle] = useState<string>('')

  const fetchAllPosts = (_cursor: string, profiles?: any) => {
    setLoading(true)
    setPaginationLoading(true)

    findAllPosts({
      numberPerPage: 5,
      cursor: _cursor,
      search: { profileIDs: profiles ?? '', q: '' },
    })
      .then(result => {
        if (result) {
          if (result.data.posts.length > 0) {
            setPosts(posts => [...posts, ...result.data.posts])
            setCursor(result.data.cursor)
          }
        }

        setLoading(false)
        setPaginationLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setPaginationLoading(false)
      })
  }

  const fetchAllArticles = (_cursor: string, profiles?: any) => {
    setPaginationLoading(true)
    findAllArticles({
      numberPerPage: 5,
      cursor: '',
      search: {
        q: '',
        profileIDs: profiles ?? '',
      },
    })
      .then(res => {
        setLoading(false)
        setPaginationLoading(false)
        if (res) {
          setArticles(res.data.articles)
        }
      })
      .catch(error => {
        setLoading(false)
        setPaginationLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    setPosts([])
    setCursor('')
    fetchAllPosts('')
  }, [])

  const handleChangeViewType = (event: any, newValue: any): void => {
    setTab(newValue)
    setPosts([])
    setArticles([])
    setNoMoreDate(false)
    if (newValue === 'posts') {
      setCursor('')
      fetchAllPosts('')
    } else if (newValue === 'articles') {
      setCursorArticle('')
      fetchAllArticles('')
    }
  }

  const loadMoreHandler = () => {
    if (tab == 'posts') {
      fetchAllPosts(cursor)
    } else if (tab == 'articles') {
      fetchAllArticles(cursorArticle)
    }
  }

  return (
    <Page title="Personia Platform">
      <Box>
        <div className={'row-1'}>
          <Controller
            name={'banner'}
            control={control}
            rules={{
              validate: value => {
                return value || platforms?.cover
                  ? true
                  : 'Please ensure all required fields, indicated with (*), are filled before submitting.'
              },
            }}
            render={({
              field: { onChange: fieldOnChange, value: fieldValue },
            }) => (
              <Banner
                isEditing={editing}
                errors={errors.banner}
                banner={watch('banner')}
                setBanner={banner => setValue('banner', banner)}
                handleCoverPreview={handleCoverPreview}
              />
            )}
          />
          <div className={'flex'}>
            <Controller
              name={'logo'}
              control={control}
              rules={{
                validate: value => {
                  return value || platforms?.logo
                    ? true
                    : 'Please ensure all required fields, indicated with (*), are filled before submitting.'
                },
              }}
              render={({
                field: { onChange: fieldOnChange, value: fieldValue },
              }) => (
                <Logo
                  isEditing={editing}
                  errors={errors.logo}
                  logo={watch('logo')}
                  setLogo={logo => setValue('logo', logo)}
                  handleLogoPreview={handleLogoPreview}
                />
              )}
            />
            <div style={isDesktop() ? null : { width: '100%' }}>
              <div
                style={
                  isDesktop()
                    ? null
                    : {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }
                }
              >
                {loading || platforms?.name === '' ? (
                  <Skeleton />
                ) : (
                  <Name
                    isEditing={editing}
                    errors={errors.name}
                    name={watch('name')}
                    setName={name => setValue('name', name)}
                    controllerInstance={control}
                    controllerName={'name'}
                    isRegisterPage={false}
                    loading={loading}
                    controllerRules={{
                      required: {
                        value: true,
                        message:
                          'Please ensure all required fields, indicated with (*), are filled before submitting.',
                      },
                    }}
                  />
                )}
              </div>
              <div
                style={
                  isDesktop()
                    ? null
                    : {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '-20px',
                      }
                }
              >
                <Slogan
                  isEditing={editing}
                  errors={errors.slogan}
                  slogan={watch('slogan')}
                  setSlogan={slogan => setValue('slogan', slogan)}
                  controllerInstance={control}
                  controllerName={'slogan'}
                  loading={loading}
                  isRegisterPage={false}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                  }}
                />
              </div>
            </div>

            <div className={'right'}>
              {!editing ? (
                <CButton
                  key={1}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  background={'transparent'}
                  size={'s'}
                  backgroundHover={'transparent'}
                  color={'white100'}
                  startIconSvg={<Edit />}
                  onClick={() => setEditing(true)}
                >
                  {null}
                </CButton>
              ) : (
                <CButton
                  key={1}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  margin="0 0px 0 0"
                  background={'transparent'}
                  backgroundHover={'transparent'}
                  color={'white100'}
                  startIconSvg={<Save />}
                  onClick={handleSubmit(onSubmit)}
                  border={'none'}
                >
                  {null}
                </CButton>
              )}
            </div>
          </div>
        </div>
        <div className={'row-2'}>
          <TabBox>
            <CTabs
              value={tab}
              onChange={handleChangeViewType}
              key={1}
              $padding={'20px'}
              $background={'navy60'}
              $activeBG={'navy60'}
            >
              {/*<CTab*/}
              {/*  label={'Posts'}*/}
              {/*  id={'view-tab-all-posts'}*/}
              {/*  aria-controls={'view-tabpanel-all-posts'}*/}
              {/*  value={'posts'}*/}
              {/*  disableTouchRipple*/}
              {/*  $fullWidth*/}
              {/*/>*/}
              {/*<CTab*/}
              {/*  label={'Articles'}*/}
              {/*  id={'view-tab-articles'}*/}
              {/*  aria-controls={'view-tabpanel-articles'}*/}
              {/*  value={'articles'}*/}
              {/*  disableTouchRipple*/}
              {/*  $fullWidth*/}
              {/*/>*/}
              <CTab
                label={'Journey'}
                id={'view-tab-journey'}
                aria-controls={'view-tabpanel-journey'}
                value={'journey'}
                disableTouchRipple
                $fullWidth
              />
              <CTab
                label={'About'}
                id={'view-tab-about'}
                aria-controls={'view-tabpanel-about'}
                value={'about'}
                disableTouchRipple
                $fullWidth
              />
            </CTabs>
          </TabBox>

          <div className={'body'}>
            {tab === 'posts' ? (
              loading ? (
                [1, 2, 3, 4].map(i => (
                  <PostBox loading={loading} key={i} type={2} />
                ))
              ) : posts.length === 0 ? (
                <NothingFound
                  icon="hourglass_disabled"
                  title="No Posts Found"
                  padding={'30px'}
                />
              ) : (
                <div>
                  {[...posts]
                    .sort(
                      (x: any, y: any) =>
                        new Date(y.createdAt).getTime() -
                        new Date(x.createdAt).getTime()
                    )
                    .map((post, i) => {
                      return (
                        <PostBox
                          loading={loading}
                          post={post}
                          key={i}
                          type={2}
                        />
                      )
                    })}
                  {!noMoreData ? (
                    <CButton
                      fullWidth
                      background={'navy80'}
                      color={'white100'}
                      backgroundHover={'navy100'}
                      loading={paginationLoading}
                      onClick={loadMoreHandler}
                    >
                      Load More
                    </CButton>
                  ) : (
                    <CButton
                      fullWidth
                      background={'black5'}
                      color={'black80'}
                      backgroundHover={'black3'}
                      disabled
                    >
                      No More Posts
                    </CButton>
                  )}
                </div>
              )
            ) : tab === 'articles' ? (
              loading ? (
                [1, 2, 3, 4].map(i => (
                  <ArticleBox loading={loading} key={i} type={2} />
                ))
              ) : articles.length === 0 ? (
                <NothingFound
                  icon="hourglass_disabled"
                  title="No Articles Found"
                  padding={'30px'}
                />
              ) : (
                <div>
                  {[...articles]
                    .sort(
                      (x: any, y: any) =>
                        new Date(y.createdAt).getTime() -
                        new Date(x.createdAt).getTime()
                    )
                    .map((article, i) => {
                      return (
                        <ArticleBox
                          loading={loading}
                          article={article}
                          key={i}
                          type={2}
                        />
                      )
                    })}
                  {!noMoreData ? (
                    <CButton
                      fullWidth
                      background={'navy80'}
                      color={'white100'}
                      backgroundHover={'navy100'}
                      loading={paginationLoading}
                      onClick={loadMoreHandler}
                    >
                      Load More
                    </CButton>
                  ) : (
                    <CButton
                      fullWidth
                      background={'black5'}
                      color={'black80'}
                      backgroundHover={'black3'}
                      disabled
                    >
                      No More Articles
                    </CButton>
                  )}
                </div>
              )
            ) : tab === 'journey' ? (
              <div className={'text'}>
                <div className={'right'}>
                  <div style={isMobile() ? { width: '70%' } : {}}>
                    <span>
                      {loading ? <Skeleton /> : platforms?.projectVision}
                    </span>
                  </div>

                  <Link to={'/profile'}>
                    {isDesktop() ? (
                      <CButton
                        key={1}
                        background={'transparent'}
                        size={'s'}
                        backgroundHover={'transparent'}
                        color={'white100'}
                        startIconSvg={<Edit />}
                      >
                        {null}
                      </CButton>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Edit />
                      </div>
                    )}
                  </Link>
                </div>

                <div className={'editor'}>
                  {loading || platforms?.projectMission === undefined ? (
                    <Skeleton />
                  ) : (
                    <div
                      className={'ql-container ql-snow'}
                      style={{ height: 'auto' }}
                    >
                      <div
                        className={'ql-editor'}
                        dangerouslySetInnerHTML={{
                          __html: decodeURIComponent(platforms?.projectMission),
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            ) : tab === 'about' ? (
              <div className="text">
                <div className={'right'}>
                  <div>
                    <span>Overview</span>
                  </div>
                  <Link to={'/UpdateAbout'}>
                    {isDesktop() ? (
                      <CButton
                        key={1}
                        background={'transparent'}
                        size={'s'}
                        backgroundHover={'transparent'}
                        color={'white100'}
                        startIconSvg={<Edit />}
                      >
                        {null}
                      </CButton>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Edit />
                      </div>
                    )}
                  </Link>
                </div>
                <div>{loading ? <Skeleton /> : platforms?.overview}</div>
                <div>
                  <span>Website</span>
                  <a href="https://embodia.io/">
                    {loading ? <Skeleton /> : platforms?.website}
                  </a>
                </div>
                <div>
                  <span>Phone Number</span>
                  <a href="tel:+(307) 555-0133">
                    {loading ? <Skeleton /> : platforms?.phoneNumber}
                  </a>
                </div>
                <div>
                  <span>Address</span>
                  <span>{loading ? <Skeleton /> : platforms?.address}</span>
                </div>
                <div>
                  <span>Company Size</span>
                  <span>{loading ? <Skeleton /> : platforms?.companySize}</span>
                </div>
                <div>
                  <span>Founded</span>
                  <span>
                    {loading ? (
                      <Skeleton />
                    ) : platforms?.fundingStartDate ? (
                      new Date(platforms.fundingStartDate)
                        .toLocaleDateString('en-CA')
                        .replace(/\//g, '-')
                    ) : (
                      'No date available'
                    )}
                  </span>
                </div>
                <div>
                  <span>Specialties</span>
                  <span>{loading ? <Skeleton /> : platforms.specialties}</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Box>
    </Page>
  )
}
