import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model'
import { registerPlatformJourney } from '../../../models/platform/registerPlatformInfo'
import validator from 'validator'
import { preview } from '../../../models/platform/preview.model'
import { breakpoints } from '../../../config'

const LogoStyle = styled.div<{ borderColor: string }>`
  height: 162px;
  width: 162px;
  background: ${({ theme }) => theme.gray100};
  position: relative;
  border-radius: 1000px;
  top: -80px;
  left: 16px;
  border: 1px solid ${({ theme, borderColor }) => theme[borderColor]};
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    height: 72px;
    width: 72px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 72px !important;
      width: 72px !important;
    }
  }

  > p {
    font-size: 24px;
    font-weight: 600;
    font-family: Inter;
    color: ${({ theme }) => theme.white100};
    line-height: 32px;
    text-align: center;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  > img {
    height: 162px;
    width: 162px;
    border-radius: 1000px;
  }
`

export interface Props {
  platform: preview
}


export function Logo(props: Props) {

  const [selectedLogo, setSelectedLogo] = useState<File>(null)
  const { platform } = props
useEffect(()=>{
  // console.log(platform.logo)

  setSelectedLogo(platform?.logo)
},[platform])
  return (
    <>
      <LogoStyle borderColor={'black100'}>
        {platform.logo != null  && (
          // <img loading={"lazy"} src={`https://greenia.infura-ipfs.io/ipfs/${platform.logo}`}  alt={''}/>
          <img src={URL.createObjectURL(platform.logo)} />
        )}
      </LogoStyle>
    </>
  )
}
