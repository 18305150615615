import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { FullLoader } from '../components/custom'
import { set } from '../redux/slices/user'
import { loginCheck } from '../apis/auth.apis'
import { ApiErrorData } from '../apis/http.api'
import { useNavigate } from 'react-router-dom'
import { platformAccount } from '../apis/platform.apis'
import { PlatformProfileModel } from '../models/platform/platform-profile.model'

interface Props {
  children: ReactNode
}

export function AuthProvider(props: Props) {
  const { children } = props
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()
    const [platformData, setPlatformData] = useState<PlatformProfileModel>()

  useEffect(() => {
    if (localStorage.getItem('token')) {
      loginCheck()
        .then(result => {
          if (result.data.user) {
            dispatch(set({ ...result.data.user }))
            try {
              platformAccount()
                .then(res => {
                  if (res.data.account) {
                    setPlatformData(res.data.account)
                  }
                })
                .catch(error => {
                  navigate('/auth/register')
                })
              if (platformData?.name) {
                navigate('/auth/register')
              } else {
                navigate('/')
              }
            } catch (error) {
              navigate('/auth/register')
            }
          }
          setLoading(false)
        })
        .catch((error: ApiErrorData) => {
          setLoading(false)
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [])

  return <>{loading ? <FullLoader loaderStatus={loading} /> : children}</>
}
