import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { registerPlatformJourney } from '../../models/platform/registerPlatformInfo'
import { preview } from '../../models/platform/preview.model'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'

const initialState: PlatformProfileModel = {
  creator: {
    id: '',
  },
  id: '',
  name: '',
  slogan: '',
  cover: '',
  logo: '',
  abstract: '',
  overview: '',
  description: '',
  date: new Date(),
  projectHistory: '',
  projectCompellingVideo: '',
  projectVision: '',
  projectMission: '',
  requestedFund: 0,
  fundingStartDate: new Date(),
  email: '',
  phoneNumber: '',
  address: '',
  website: '',
  about: '',
  companySize: '',
  specialties: '',
  socialLinks: [],
  did: '',
  walletAddress: '',
  posts: [],
  postsCount: 0,
  articles: [],
  articlesCount: 0,
  nakamaID: '',
  publicEncryptionDID: {
    id: '',
  },
  wallet: null,
}

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    set: (state: any, action: PayloadAction<PlatformProfileModel>) => {
      return { ...action.payload }
    },
    update: (
      state: any,
      action: PayloadAction<Partial<PlatformProfileModel>>
    ) => {
      return { ...state, ...action.payload }
    },
    logout: (state: any) => {
      return { ...state, ...initialState }
    },
  },
})

export const { set, update, logout } = platformSlice.actions

export default platformSlice.reducer
