import { configureStore } from '@reduxjs/toolkit'
import userSlice from './redux/slices/user'
import postsSlice from './redux/slices/post'
import  userPreviewSlice from './redux/slices/user-preview'
import  updateAboutSlice  from './redux/slices/updateAboutRedux'
import updateJourneySlice  from './redux/slices/updateJourney'
import platformSlice  from './redux/slices/platform'

export const store = configureStore({
  reducer: {
    user: userSlice,
    posts: postsSlice,
    userPreview: userPreviewSlice,
    updateAbout: updateAboutSlice,
    updateJourney: updateJourneySlice,
    platform: platformSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
