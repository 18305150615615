import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../redux/hooks'
import { CropperJs } from '../custom/cropper'
import { CModal } from '../mui'
import { DropZone } from '../custom'
import { Card } from '../structure'
import { FieldError } from 'react-hook-form'
import { breakpoints } from '../../config'
import { isDesktop } from '../../utils/detect-screen'
import { create, IPFSHTTPClient } from 'ipfs-http-client'
import { usePreviousMonthDisabled } from '@mui/x-date-pickers/internals'
import { useSearchParams } from 'react-router-dom'
import { platformAccount } from '../../apis/platform.apis'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'

const LogoStyle = styled.div<{ borderColor: string }>`
  height: 162px;
  width: 162px;
  background: ${({ theme }) => theme.gray100};
  position: relative;
  border-radius: 1000px;
  top: -80px;
  left: 16px;
  border: 1px solid ${({ theme, borderColor }) => theme[borderColor]};
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    height: 72px;
    width: 72px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 72px !important;
      width: 72px !important;
    }
  }

  > p {
    font-size: 24px;
    font-weight: 600;
    font-family: Inter;
    color: ${({ theme }) => theme.white100};
    line-height: 32px;
    text-align: center;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  > img {
    height: 162px;
    width: 162px;
    border-radius: 1000px;
  }
`

export interface Props {
  isEditing: boolean
  logo: Blob
  setLogo: (banner: Blob) => void
  errors: FieldError
  handleLogoPreview: (e: File) => void
  ipfslogo?: (ipfsLogo) => void
}

export function Logo(props: Props) {
  const { isEditing, logo, setLogo, errors, handleLogoPreview, ipfslogo } = props
  const user = useAppSelector(state => state.userPreview)
  const _user = useAppSelector(state => state.user)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [logoFile, setLogoFile] = useState<File>(null)
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null)
  const [ipfsLogo, setIpfsLogo] = useState(null)
  const [platformData, setPlatformData] = useState<PlatformProfileModel>()

  useEffect(() => {
    setLoading(true)

    platformAccount()
      .then(res => {
        setLoading(false)

        if (res?.data?.account) {
          setPlatformData(res.data.account)
        } else {
          console.log('No account data found')
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = create({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  useEffect(() => {
    if (isEditing && user.logo) {
      setSelectedLogo(new File([user.logo], '', { type: user.logo.type }))
    }
  }, [isEditing, user.logo])

  const onCropBanner = async (blob: Blob | null) => {
    setLogo(blob)
    setLogoFile(null)
    setSelectedLogo(new File([blob], '', { type: blob.type }))
    if (logoFile) {
      handleLogoPreview(logoFile)
    }

    if (blob != null && ipfs != null) {
      setIpfsLogo(await ipfs?.add(blob))
      ipfslogo(ipfsLogo)
    }

    // if (blob != null) {
    //   if (ipfs != null) {
    //     setIpfsLogo(await ipfs?.add(blob))
    //     ipfslogo(ipfsLogo)
    //   }
    //   if (user.logo && typeof user.logo === 'object') {
    //     if (ipfs != null) {
    //       setIpfsLogo(await ipfs?.add(user.logo))
    //       ipfslogo(ipfsLogo.path)
    //     }
    //   }
    // }
  }

  const handleClickBanner = () => {
    setOpenModal(true)
  }

  const handleFileSelect = (files: File[]) => {
    const file = files[0]
    const maxSize = 5 * 1024 * 1024

    if (file.size > maxSize) {
      alert(
        'The image you uploaded exceeds the 5 MB limit. Please upload an image that is smaller'
      )
      return
    }

    setLogoFile(file)
  }

  return (
    <>
      <LogoStyle borderColor={errors ? 'red100' : 'black100'}>
        {(_user.did === '' || isEditing) && (
          <p onClick={handleClickBanner}>+</p>
        )}
        {selectedLogo && (
          <img src={URL.createObjectURL(selectedLogo)} alt="Logo" />
        )}
        {!selectedLogo && platformData?.logo && (
          <img
            src={`https://greenia.infura-ipfs.io/ipfs/${platformData?.logo}`}
            alt="Logo"
          />
        )}
      </LogoStyle>

      <CModal
        open={openModal}
        onClose={() => {
          setLogo(null)
          setOpenModal(false)
        }}
        title=""
        background={'navy90'}
      >
        {logoFile ? (
          <CropperJs
            src={URL.createObjectURL(logoFile)}
            onCrop={blob => {
              setOpenModal(false)
              onCropBanner(blob)
            }}
            aspectRatio={1}
            selectedFile={logoFile}
            setSelectedFile={item => {
              setLogoFile(item)
            }}
            aspectRatioEditable={false}
            submitLoading={loading}
          />
        ) : (
          <DropZone
            label={'Logo'}
            disabled={loading}
            acceptedFiles={[
              'image/jpeg',
              'image/jpg',
              'image/png',
              'image/gif',
            ]}
            selectedFiles={[]}
            type={'avatar'}
            onSelect={handleFileSelect}
          />
        )}
      </CModal>
    </>
  )
}
