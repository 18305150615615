import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ReactElement, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Page } from '../../components/structure'
import { UserBox } from '../../components/user'
import {
  CButton,
  CIconButton,
  CMenu,
  CMenuItem,
  CSkeleton,
} from '../../components/mui'
import { useGlobalContext, useCeramicContext } from '../../contexts'
import _ from 'lodash'
import {
  Article,
  Post,
  PostComment,
} from 'allostasis-js-sdk/lib/types/allostasis'
import { Avatar } from '@mui/material'
import { CommentBox } from '../../components/article/comment'
import dayjs from 'dayjs'
import ReactTimeAgo from 'react-time-ago'
import { Img } from 'react-image'
import { Download } from '@mui/icons-material'
import { ArticleModel } from '../../models/article.model'
import { doArticleLike, findSingleArticle } from '../../apis/article.apis'
import { doPostLike } from '../../apis/post.apis'
import { PostModel } from '../../models/post.model'
import { update } from '../../redux/slices/post'

const Box = styled.div`
  background: ${props => props.theme.navy80};
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  display: block;
  text-decoration: none;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    justify-content: space-between;

    > a {
      display: flex;
      text-decoration: none;
      flex-direction: row;

      > .img {
        margin-right: 15px;
      }

      > .author {
        flex-grow: 1;
        margin-right: 15px;

        > span {
          font-size: 14px;
          font-weight: 500;
          color: ${props => props.theme.white100};
          display: block;
          text-decoration: none;
        }

        > small {
          font-size: 12px;
          font-weight: 200;
          color: ${props => props.theme.black50};
          text-decoration: none;
        }
      }
    }

    > .type {
      display: flex;
      align-items: center;

      > .material-symbols-outlined {
        font-size: 20px !important;
        font-weight: 200;
        color: ${props => props.theme.white100};
        text-decoration: none;
      }

      > p {
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.black50};
        display: block;
        text-decoration: none;
      }
    }
  }

  > .body {
    text-decoration: none;
    flex-direction: row;
    align-items: flex-start;

    > .img {
      width: 100%;
      margin-right: 15px;

      > img {
        display: block;
        width: 100%;
        border-radius: 8px;
        max-height: 630px;
      }
    }

    > .content {
      flex-grow: 1;

      > .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > h5 {
          font-size: 18px;
          font-weight: 400;
          color: ${props => props.theme.white100};
        }

        > small {
          color: ${props => props.theme.white100};
        }

        > .right {
        }
      }

      > .body {
        margin-top: 15px;

        > .blur {
          position: relative;

          > p {
            font-size: 14px;
            color: ${props => props.theme.white100};
            line-height: 3rem;
            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            margin-bottom: 20px;
            filter: blur(3.5px);
          }

          > .links {
            position: absolute;
            left: 20px;
            right: 20px;
            top: 40%;
            align-content: space-between;
            align-items: center;
            align-self: center;
            display: flex;
            flex-wrap: wrap;
            text-align: center;

            > .column {
              flex-basis: 0;
              flex-grow: 1;
              align-self: center;
              align-items: center;
              width: 100%;
              margin: 0 auto 0 auto;

              > p {
                font-size: 14px;
                color: ${props => props.theme.green100};
                line-height: 1.3rem;
                display: block;
                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow: hidden;
                margin-bottom: 20px;
                margin-top: 20px;
              }
            }
          }
        }

        > div > p,
        span {
          font-size: 14px;
          color: ${props => props.theme.white100};
          line-height: 1.6rem;
          display: block;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: pre-line;
          overflow: hidden;
          margin-bottom: 20px;
          text-align: justify;
        }

        > div > h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        li,
        ol {
          color: ${props => props.theme.white100};
          line-height: 1.6rem;
        }

        > a {
          color: ${props => props.theme.green100};
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration: none;
          width: 100%;
          padding: 10px 24px;
          float: right;
        }

        > img {
          margin-top: 20px;
        }

        > .tags {
          display: flex;

          > a {
            color: ${props => props.theme.green100};
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration: none;
            padding: 10px;
            float: right;
          }
        }
      }

      > .actions {
        margin-top: 15px;
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        align-content: space-between;
        width: 100%;

        > .left {
          display: flex;
          width: 50%;

          > p {
            color: ${props => props.theme.white100};
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration: none;
            padding: 10px;
          }
        }

        > .right {
          display: flex;
          width: 50%;
          flex-direction: row-reverse;

          > div {
            flex-direction: row;
            align-items: center;
            margin-right: 15px;

            > span {
              color: ${props => props.theme.white100};
              font-size: 12px;
              font-weight: 500;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  &:hover {
  }

  &.type-2 {
    > .body {
      display: block;

      > .img {
        width: 100%;
        margin: 10px auto 15px auto;

        > img {
          width: 100%;
        }
      }
    }
  }
`

export function ArticleDetailPage(): ReactElement {
  setTimeout(() => {
    if (localStorage.getItem('wallet_id')) {
      localStorage.setItem('@w3m/wallet_id', localStorage.getItem('wallet_id'))
    }
  }, 9000)

  const { id } = useParams()
  const { makeAlert } = useGlobalContext()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)
  const [post, setPost] = useState<PostModel | undefined>()
  // const { allostasis, locale, dateOption } = useCeramicContext()

  const [loading, setLoading] = useState<boolean>(true)
  const [article, setArticle] = useState<ArticleModel | undefined>()

  const [commentsLoading, setCommentsLoading] = useState<boolean>(true)
  const [, setComments] = useState<PostComment[]>([])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })

    findSingleArticle(id ?? '')
      .then(async res => {
        setArticle(res.data.article)
        setLoading(false)
      })
      .catch(() => {
        navigate('/')
      })
  }, [])

  const [moreAnchorEl, setMoreAnchorEl] = useState(null)
  const handleMoreClick = (event: any): void => {
    setMoreAnchorEl(event.currentTarget)
  }
  const handleMoreClose = (): void => {
    setMoreAnchorEl(null)
  }
  const moreOpen = Boolean(moreAnchorEl != null)

  const [liking, setLiking] = useState<boolean>(false)

  const like = async (e: Event) => {
    e.preventDefault()
    setLiking(true)
    if (user.did !== '') {
      setLiking(true)
      try {
        if (user.did !== '') {
          const res = await doArticleLike(article.id)
          if (res) {
            setLiking(false)
            makeAlert('success', 'Article liked')
            if (article != null && res != null && res.data.like) {
              dispatch(
                update({
                  ...article,
                  likesCount: _.get(article, 'likesCount', 0) + 1,
                  likes: [
                    ..._.get(article, 'likes', []),
                    {
                      articleID: article?.id,
                      profileID: user.id,
                      isDeleted: false,
                    },
                  ],
                })
              )

              setArticle({
                ...article,
                likesCount: _.get(article, 'likesCount', 0) + 1,
                likes: [
                  ..._.get(article, 'likes', []),
                  {
                    articleID: article?.id,
                    profileID: user.id,
                    isDeleted: false,
                  },
                ],
              })
            } else {
              makeAlert('success', 'Post unliked')
              setLiking(false)
              if (article != null && res != null) {
                dispatch(
                  update({
                    ...article,
                    likesCount: _.get(article, 'likesCount', 0) - 1,
                    likes: [
                      ..._.get(article, 'likes', []).filter(
                        x => x?.profileID !== user.id
                      ),
                    ],
                  })
                )

                setArticle({
                  ...article,
                  likesCount: _.get(article, 'likesCount', 0) - 1,
                  likes: [
                    ..._.get(article, 'likes', []).filter(
                      x => x.profileID !== user.id
                    ),
                  ],
                })
              }
            }
          }
        }
      } catch (err) {
        setLiking(false)
      }
    }
  }

  const copyUrl = (e: Event): void => {
    e.stopPropagation()
    setMoreAnchorEl(null)

    navigator.clipboard.writeText(
      window.location.href + 'articles/get/' + article?.id
    )
    makeAlert('success', 'Copied')
  }

  const reportBug = (e: Event): void => {
    e.stopPropagation()
    setMoreAnchorEl(null)
  }

  return (
    <Page
      title={'Article Page'}
      sidebar={
        <>
          <div className={'back'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              onClick={() => navigate(-1)}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </div>
        </>
      }
    >
      <Box className={`article type-${'1'}`}>
        <div className={'header'}>
          <Link to={'/u/' + article?.profile?.id}>
            <div className="img">
              {loading ? (
                <CSkeleton width={40} height={40} borderradius={'20px'} />
              ) : (
                <Avatar
                  alt={article?.profile?.name}
                  src={`https://greenia.infura-ipfs.io/ipfs/${article?.profile?.avatar}`}
                />
              )}
            </div>

            <div className="author">
              {loading ? (
                <>
                  <CSkeleton width={200} height={10} marginBottom={'5px'} />
                  <CSkeleton width={100} height={10} />
                </>
              ) : (
                <>
                  <span>{article?.profile?.name || 'New Face'}</span>
                  <small>{article?.visualAbstract}</small>
                </>
              )}
            </div>
          </Link>

          <div className="type">
            {loading ? (
              <CSkeleton width={50} height={10} />
            ) : (
              <>
                <p>
                  <ReactTimeAgo date={article?.createdAt} locale="en-US" />
                </p>
                <CIconButton
                  onClick={handleMoreClick}
                  backgroundColor={'transparent'}
                  backgroundColorHover={'transparent'}
                  icon="more_horiz"
                />
              </>
            )}
          </div>
        </div>

        <div className={'body'}>
          <div className="content">
            <div className="body">
              {loading ? (
                <>
                  <CSkeleton width={200} height={20} marginBottom={'15px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'40%'} height={10} />

                  <CSkeleton
                    width={200}
                    height={20}
                    marginBottom={'15px'}
                    marginTop={'15px'}
                  />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'100%'} height={10} marginBottom={'5px'} />
                  <CSkeleton width={'25%'} height={10} />
                </>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeURIComponent(article.body),
                    }}
                  ></div>

                  <div className={'tags'}>
                    {article?.tags
                      ? article?.tags
                          .filter(item => item != '')
                          .map(tag => (
                            <Link to={'/articles/search/' + tag}>#{tag}</Link>
                          ))
                      : null}
                  </div>
                </>
              )}
            </div>
          </div>

          {article?.externalURL && (
            <a
              href={`https://greenia.infura-ipfs.io/ipfs/${article?.externalURL}`}
              target={'_blank'}
              className={'download'}
              style={{ marginTop: '10px', marginBottom: '30px' }}
            >
              <CButton
                key={1}
                background={'navy25'}
                backgroundHover={'navy25'}
                backgroundDisabled={'gray60'}
                margin="10px 0 30px 0"
                form={'edit-profile'}
                type={'submit'}
                fullWidth
                startIconSvg={<Download />}
              >
                Download Article attachment
              </CButton>
            </a>
          )}

          {loading ? (
            <div className="img">
              <CSkeleton width={150} height={150} borderradius={'8px'} />
            </div>
          ) : article?.attachment != '' ? (
            <div className="img">
              <Img
                src={`https://greenia.infura-ipfs.io/ipfs/${article?.attachment}`}
                loading={'lazy'}
              />
            </div>
          ) : null}

          <div className="content">
            <div className={'actions'}>
              <div className={'left'}>
                {/*<p>235 views</p>*/}
                <p>
                  {article?.createdAt &&
                    new Date(article?.createdAt).toLocaleString()}
                </p>
              </div>
              <div className={'right'}>
                <div>
                  <CIconButton
                    icon={'favorite'}
                    loading={liking}
                    disabled={liking}
                    // onClick={like}
                    customColor={'white100'}
                    backgroundColorHover={'transparent'}
                    backgroundColor={'transparent'}
                  />
                  <span>{loading ? 0 : article?.likesCount}</span>
                </div>
                <div>
                  <CIconButton
                    icon={'comment'}
                    loading={false}
                    disabled={true}
                    customColor={'white100'}
                    backgroundColorHover={'transparent'}
                    backgroundColor={'transparent'}
                  />
                  <span>{loading ? 0 : article?.commentsCount}</span>
                </div>
              </div>
            </div>
          </div>

          {/*<div style={{ marginTop: '20px' }}>*/}
          {/*  <CommentBox*/}
          {/*    makeAlert={makeAlert}*/}
          {/*    comments={article?.comments ?? []}*/}
          {/*    post={article}*/}
          {/*    loading={commentsLoading}*/}
          {/*    postId={article?.id ?? ''}*/}
          {/*    onSubmit={comment => {*/}
          {/*      setArticle({*/}
          {/*        ...article,*/}
          {/*        comments: [...(article?.comments ?? []), comment],*/}
          {/*        commentsCount: (article?.commentsCount ?? 0) + 1,*/}
          {/*      })*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>

        <CMenu
          open={moreOpen}
          id="options-menu"
          anchorEl={moreAnchorEl}
          onClose={handleMoreClose}
          anchorOriginHorizontal="left"
          transformOriginHorizontal="left"
          anchorOriginVertical="bottom"
          transformOriginVertical="top"
        >
          <CMenuItem
            color={'white100'}
            onClick={e => {
              copyUrl(e)
            }}
          >
            Copy link
          </CMenuItem>
          <CMenuItem
            color={'red100'}
            onClick={e => {
              reportBug(e)
            }}
          >
            <span>Report a bug</span>
          </CMenuItem>

          {article?.profileID === user?.id ? (
            <>
              <Link to={`/articles/edit/${article?.id ?? ''}`}>
                <CMenuItem
                  color={'white100'}
                  onClick={() => {
                    handleMoreClose()
                  }}
                >
                  Edit Article
                </CMenuItem>
              </Link>
            </>
          ) : null}
        </CMenu>
      </Box>
    </Page>
  )
}
