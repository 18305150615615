import React, { ReactElement } from 'react';
import { ROUTES } from './route-path';
import {
  HomePage,
} from '../pages';
import { LoginPage, RegisterPage } from '../pages/auth'
import { EditPostPage, NewPostPage, PostPage, SearchPostPage } from '../pages/posts'
import { ArticleDetailPage, EditArticlePage, NewArticlePage, SearchArticlePage } from '../pages/articles'
import { ProfilePage } from '../pages/profile/platformInfo'
import { PlatformBox } from '../components/platform';
import { PlatformDetailPage, PlatformsPage } from '../pages/platforms';
import { AboutPage } from '../pages/profile/updateAbout';

export const PAGE_ROUTES: {
  id: number
  isPrivate: boolean
  prevent: boolean
  deactivate: boolean
  path: string
  element: ReactElement
}[] = [
  {
    id: 1,
    isPrivate: false,
    prevent: false,
    deactivate: false,
    path: ROUTES.AUTH_LOGIN_PAGE,
    element: <LoginPage />,
  },
  {
    id: 2,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.AUTH_REGISTER_PAGE,
    element: <RegisterPage />,
  },
  {
    id: 3,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.INDEX,
    element: <HomePage />,
  },
  {
    id: 3,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.POSTS_NEW,
    element: <NewPostPage />,
  },
  {
    id: 4,
    isPrivate: false,
    prevent: false,
    deactivate: false,
    path: ROUTES.POSTS_DETAILS,
    element: <PostPage />,
  },
  {
    id: 5,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.POSTS_EDIT,
    element: <EditPostPage />,
  },
  {
    id: 10,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.ARTICLES_NEW,
    element: <NewArticlePage />,
  },
  {
    id: 11,
    isPrivate: false,
    prevent: false,
    deactivate: false,
    path: ROUTES.ARTICLES_DETAIL,
    element: <ArticleDetailPage />,
  },
  {
    id: 12,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.ARTICLES_EDIT,
    element: <EditArticlePage />,
  },
  {
    id: 13,
    isPrivate: false,
    prevent: false,
    deactivate: false,
    path: ROUTES.ARTICLES_SEARCH,
    element: <SearchArticlePage />,
  },
  {
    id: 7,
    isPrivate: false,
    prevent: false,
    deactivate: false,
    path: ROUTES.POSTS_SEARCH,
    element: <SearchPostPage />,
  },
  {
    id: 7,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.ProfilePage,
    element: <ProfilePage />,
  },
  {
    id: 8,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.PreviewProfile,
    element: <PlatformDetailPage />,
  },
  {
    id: 9,
    isPrivate: true,
    prevent: false,
    deactivate: false,
    path: ROUTES.UPDATE_ABOUT,
    element: <AboutPage />,
  },
]
