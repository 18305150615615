import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../redux/hooks'
import { CButton, CModal, CTextField } from '../mui'
import { ReactComponent as Edit } from '../../assets/svg/edit.svg'
import { Controller, FieldError } from 'react-hook-form'
import { breakpoints } from '../../config'
import { isDesktop } from '../../utils/detect-screen'
import { Skeleton } from '@mui/material'

const SloganStyle = styled.div<{ textColor: string }>`
  display: flex;
  position: relative;
  left: 62px;
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    position: static;
  }
  > p {
    font-size: 16px;
    font-weight: 400;
    font-family: Inter;
    color: ${({ theme, textColor }) => theme[textColor]};
    line-height: 24px;
    margin-bottom: 20px;
  }

  > .icon {
    position: relative;
    left: 10px;
    cursor: pointer;
  }
`

const ModalStyle = styled.div`
  > p {
    font-size: 20px;
    font-weight: 600;
    font-family: Inter;
    color: ${({ theme }) => theme.white100};
    line-height: 28px;
  }

  > .flex {
    margin-top: 20px;
    display: flex;
    float: right;
    margin-bottom: 20px;
  }
`

export interface Props {
  isEditing: boolean
  slogan: string
  setSlogan: (name: string) => void
  errors: FieldError
  controllerInstance?: any
  controllerName?: string
  controllerRules?: any
  loading: boolean
  isRegisterPage: boolean
}

export function Slogan(props: Props) {
  const {
    isEditing,
    slogan,
    setSlogan,
    errors,
    controllerName,
    controllerRules,
    controllerInstance,
    loading,
    isRegisterPage,
  } = props

  const user = useAppSelector(state => state.userPreview)
  const [openModal, setOpenModal] = useState<boolean>(false)
  // const [loading, setLoading] = useState<boolean>(false)

  const handleSetName = (event: any) => {
    setSlogan(event.target.value)
  }

  const handleClickTitle = () => {
    setOpenModal(true)
  }

  const handleSaveName = () => {
    setOpenModal(false)
  }

  const [rules, setRules] = useState<any>()
  useEffect(() => {
    setRules({
      pattern: {
        value: /^[a-zA-Z\s\d`~!@#$%^&*()-_=+[\]{};:'",.<>/?\\|]*$/,
        message: 'Enter English characters',
      },
      ...controllerRules,
    })
  }, [])

  return (
    <>
      <Controller
        name={controllerName}
        control={controllerInstance}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <>
            <SloganStyle textColor={errors ? 'red100' : 'white100'}>
              {loading ? (
                <Skeleton />
              ) : slogan ? (
                <p>{slogan}</p>
              ) : (
                isRegisterPage && <p>Slogan of the company</p>
              )}
              {isEditing && (
                <div className="icon" onClick={handleClickTitle}>
                  <Edit />
                </div>
              )}
            </SloganStyle>

            <CModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              title=""
              background={'navy90'}
            >
              <ModalStyle>
                <p>Enter Slogan of the company</p>
                <div style={{ margin: '20px' }}></div>
                <CTextField
                  label="Slogan of the company"
                  type="text"
                  value={slogan}
                  background={'navy100'}
                  onChange={handleSetName}
                  placeholder={'Company Name'}
                />

                <div className="flex">
                  <CButton
                    background={'red130'}
                    color="white100"
                    backgroundHover={'red130'}
                    backgroundDisabled={'red130'}
                    size={'s'}
                    onClick={() => {
                      setOpenModal(false)
                      setSlogan(user.slogan)
                    }}
                    margin={'0 15px 0 0'}
                    loading={loading}
                    disabled={loading}
                  >
                    Cancel
                  </CButton>
                  <CButton
                    size={'s'}
                    background="navy25"
                    color="white100"
                    backgroundHover="navy25"
                    backgroundDisabled="navy25"
                    onClick={handleSaveName}
                    loading={loading}
                    disabled={loading}
                  >
                    Submit
                  </CButton>
                </div>
              </ModalStyle>
            </CModal>
          </>
        )}
      />
    </>
  )
}
