import { CButton } from '../mui'
import { useEffect, useState } from 'react'
import { CModalTwo } from '../mui/ModalTwo'
import { LoginChildSec } from '../newStructures/LoginChild.style'
import { LoginWalletConnect } from './login-wallet-connect'
import { LoginButtonQr } from './login-qr'
import { isDesktop } from '../../utils/detect-screen'
import { ReactComponent as CloseLogin } from '../../assets/svg/close-login.svg'
import { CSvgButton } from '../mui/SvgButton'
import { useAppSelector } from '../../redux/hooks'
import { platformAccount } from '../../apis/platform.apis'
import { useNavigate } from 'react-router-dom'

interface Props {
  color: string
  textcolor: string
  size: 's' | 'm' | 'l'
  isOpen?: boolean
}

export function LoginButton(props: Props) {
  const { color, textcolor, size, isOpen } = props
  const [loginState, setLoginState] = useState<boolean>(false)
  const user = useAppSelector(state => state.user)
  const navigate = useNavigate()
  const closeLoginModal = () => {
    setLoginState(false)
  }

  const startLogin = () => {
    setLoginState(true)
  }

  useEffect(() => {
    if (isOpen) {
      startLogin()
    }
  }, [isOpen])

  useEffect(() => {
    if (user && user.did) {
      platformAccount()
        .then(result => {
          if (!result.data.account.name) {
            navigate('/auth/register')
          } else {
            navigate('/')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [user])

  return (
    <>
      <LoginWalletConnect
        setMainLoginState={(state: boolean) => setLoginState(state)}
        textColor={textcolor}
        size={size}
        background={color}
        startIcon={'login'}
        loadingColor={'green100'}
        fullWidth={true}
        backgroundHover={color}
        color={textcolor}
        text={'Login'}
      />
    </>
  )
}
