import React, { useState } from 'react'
import { LoginChildSec } from '../../newStructures/LoginChild.style'
import { CButtonTwo } from '../../mui/ButtonTwo'
import { MyInputTwo } from '../../custom/inputTwo'
import MetamaskIcon from '../../../assets/svg/metamask.svg'
import { registerAcc } from '../../../apis/auth.apis'
import { ApiErrorData } from '../../../apis/http.api'
import { useAppDispatch } from '../../../redux/hooks'
import { useGlobalStyleContext } from '../../../contexts'
import { ProfileModel } from '../../../models/profile.model'
import { isDesktop } from '../../../utils/detect-screen'
import { CSvgButton } from '../../mui/SvgButton'
import { ReactComponent as CloseLogin } from '../../../assets/svg/close-login.svg'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model'

type Props = {
  wallet: string
  did: string
  setTempUser: (user: PlatformProfileModel) => void
  setLoginStep: (
    step:
      | 'welcome'
      | 'signup-question'
      | 'signup'
      | 'none'
      | 'loading'
      | 'walletIframe'
      | 'welcome-final'
  ) => void
  onClose?: () => void
}

export const Signup = ({ wallet, did, setTempUser, setLoginStep,onClose }: Props) => {
  const dispatch = useAppDispatch()
  const { makeAlert } = useGlobalStyleContext()

  const [userName, setUserName] = useState('')
  const [userNameError, setUserNameError] = useState('')

  const [loading, setLoading] = useState<boolean>(false)

  const register = (event: any, username: string) => {
    event.preventDefault()
    setLoading(true)
    let goOn = true
    setUserNameError('')

    if (!userName) {
      setUserNameError('Required field')
      goOn = false
    }

    if (!goOn) setLoading(false)
    else {
      registerAcc(username)
        .then(result => {
          setLoading(false)
          if (result.data.user) {
            setTempUser(result.data.user)
            setLoginStep('welcome-final')
          }
        })
        .catch((error: ApiErrorData) => {
          setLoading(false)
          makeAlert('error', error.message)
        })
    }
  }

  const onChange = (e: any) => {
    e.preventDefault()
    setUserNameError('')
    setUserName(e.target.value)
  }

  return isDesktop() ? (
    <LoginChildSec>
      <CSvgButton
        icon={<CloseLogin />}
        disabled={false}
        onClick={onClose}
        customColor={'white100'}
        backgroundColorHover={'white'}
        backgroundColor="#140e26"
        style={{
          position: 'absolute',
          right: '0px',
          top: '0px',
          marginTop: '15px',
          marginRight: '15px',
        }}
      />
      <div className="top-heading">
        <h3 className="title">Create Account</h3>
      </div>
      <form
        style={{ marginTop: '-40px' }}
        onSubmit={event => register(event, userName)}
      >
        <div
          style={{ width: '100%', justifyContent: 'center' }}
          className="items-box-col"
        >
          <div style={{ width: '70%' }}>
            <MyInputTwo
              placeholder="Enter Your Name"
              label="Name"
              value={userName}
              onChange={onChange}
              name="name"
              boxIcon={MetamaskIcon}
              background="navy100"
              border={'borderInp10'}
              color="white"
              alert={userNameError}
              height={'48'}
            />
          </div>
          <div style={{ width: '70%', overflow: 'hidden' }}>
            <MyInputTwo
              placeholder=""
              label="Wallet"
              name="name"
              boxIcon={MetamaskIcon}
              background="gray20"
              border={'gray20'}
              color="white"
              boxType="metamask"
              boxText={`${wallet}`}
              height={'48'}
              colorSpan="black"
            />
          </div>
        </div>
        <div style={{ marginTop: '-30px' }} className="loading">
          <CButtonTwo
            color="#140E26"
            background="#39DBB2"
            backgroundHover="#2aaa8a"
            variant="filled"
            fullWidth
            type="submit"
            size="s"
            fontWeight={600}
            fontSize="12px"
            maxwidth="70%"
            loadingColor="black100"
            loading={loading}
            height="48px"
            border={'transparent'}
          >
            Create Account
          </CButtonTwo>
        </div>
      </form>
    </LoginChildSec>
  ) : (
    <LoginChildSec>
      <div className="top-heading">
        <h3 className="title">Create Account</h3>
      </div>
      <form onSubmit={event => register(event, userName)}>
        <div className="items-box-col">
          <MyInputTwo
            placeholder="Enter Your Name"
            label="Name"
            value={userName}
            onChange={onChange}
            name="name"
            boxIcon={MetamaskIcon}
            background="gray70"
            border="gray60"
            color="white100"
            alert={userNameError}
          />
          <MyInputTwo
            placeholder=""
            label="Wallet"
            name="name"
            boxIcon={MetamaskIcon}
            background="gray70"
            border="gray60"
            color="white100"
            boxType="metamask"
            boxText={`${wallet}`}
          />
        </div>
        <div className="loading">
          <CButtonTwo
            color="#140E26"
            background="#39DBB2"
            backgroundHover="#2aaa8a"
            variant="filled"
            fullWidth
            type="submit"
            size="s"
            fontWeight={600}
            fontSize="12px"
            maxwidth="296px"
            loadingColor="black100"
            loading={loading}
            height="40px"
          >
            Sign up
          </CButtonTwo>
        </div>
      </form>
    </LoginChildSec>
  )
}
