import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Card, Page } from '../../components/structure'
import styled from 'styled-components'
import { Banner, Logo, Name, Slogan } from '../../components/account'
import 'cropperjs/dist/cropper.min.css'
import { Grid } from '@mui/material'
import {
  ControllerEditorField,
  ControllerTextField,
} from '../../components/rhf'
import { CButton } from '../../components/mui'
import { SubmitHandler, useForm } from 'react-hook-form'
import { GlobalContext /*useCeramicContext*/ } from '../../contexts'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { MyBlobToBuffer } from '../../utils/file'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { useNavigate } from 'react-router-dom'
import { set } from '../../redux/slices/user'
import { UserBox } from '../../components/user'
import {
  platformAccount,
  platformRequest,
  updatePlatform,
} from '../../apis/platform.apis'
import { ProfileModel } from '../../models/profile.model'
import {  } from '../../redux/slices/user-preview'
import { preview } from '../../models/platform/preview.model'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 60px;

    > .flex {
      display: flex;
    }
  }

  > .row-2 {
    position: relative;
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 30px;
    padding: 15px 15px 90px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    > div > .body > p {
      font-family: Inter;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${({ theme }) => theme.white40};
      margin-bottom: 20px;
    }

    > div > .footer {
      position: absolute;
      right: 0;
    }
  }
`

interface Inputs {
  description: string
  abstract: string
  general: string
}

export function ProfilePage(): ReactElement {
  const user = useAppSelector(state => state.user)
  const navigate = useNavigate()
  const [editing, setEditing] = useState<boolean>(false)
  const [queryError, setQueryError] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    setError,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({})

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { makeAlert } = useContext(GlobalContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [platforms, setPlatforms] = useState<PlatformProfileModel>()

  useEffect(() => {
    platformAccount()
      .then(res => {
        setLoading(false)
        if (res.data.account) {
          setPlatforms(res.data.account)
        }
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
  }, [])


  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsSubmitting(true)
    var goOn = true

    if (
      watch('description') === '<p><br></p>' ||
      watch('description') === undefined
    ) {
      setError('description', {
        message:
          'Please ensure all required fields, indicated with (*), are filled before submitting.',
      })
      goOn = false
    }

    if (!data.abstract) {
      setError('abstract', { message: 'Enter abstract' })
      goOn = false
    }
    if (!data.description) {
      setError('description', { message: 'Enter description' })
      goOn = false
    }

    if (!goOn) {
      setIsSubmitting(false)
      return
    }

    try {
      const platformRequest: platformRequest = {
        name: platforms.name,
        slogan: platforms.slogan,
        logo: platforms.logo,
        cover: platforms.cover,
        email: platforms.email,
        projectVision: data.abstract,
        projectMission: data.description,
        overview: platforms.overview,
        website: platforms.website,
        phone: platforms.phoneNumber,
        address: platforms.address,
        companySize: platforms.companySize,
        date: new Date(platforms.fundingStartDate).toLocaleDateString('en-CA').replace(/\//g, '-'),
        specialties: platforms.specialties,
      }

      await updatePlatform(platformRequest)
      .then(result => {
        navigate('/')
      })
      .catch(error => {
      })

    } catch (error) {
      setError('general', {
        message: 'An error occurred while processing the form',
      })
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      setValue('abstract', decodeURIComponent(platforms.projectVision ?? ''), {
        shouldValidate: true,
      })
      setValue(
        'description',
        decodeURIComponent(platforms.projectMission ?? ''),
        {
          shouldValidate: true,
        }
      )
      setQueryError(false)
    })()
  }, [setValue, platforms])

  return (
    <Page title="Profile">
      {/* <div className={'back'}>
    <CButton
      size={'s'}
      background={'navy100'}
      backgroundHover={'navy100'}
      backgroundDisabled={'navy100'}
      color={'white100'}
      onClick={() => navigate(-1)}
      startIcon={'keyboard_arrow_left'}
    >
      <span style={{ marginLeft: '5px' }}>Back</span>
    </CButton>
  </div> */}
      <Box>
        <div className={'row-2'}>
          <Card
            title={'Add your platform journey'}
            footer={[
              <CButton
                key={1}
                loading={isSubmitting}
                disabled={isSubmitting}
                margin="0 25px 0 0"
                form={'create-startup'}
                background={'navy25'}
                backgroundHover={'navy25'}
                color={'white100'}
                type={'submit'}
                onClick={handleSubmit(onSubmit)}
                style={{
                  width: '172px',
                  height: '45px',
                }}
              >
                Save
              </CButton>,
            ]}
          >
            <p>
              Please write about your startup’s journey. You can add videos and
              images, headlines, and titles. This will be the main pitch of your
              business, so be creative.
            </p>

            <form
              className="form"
              id="create-startup"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid item md={12} sm={12}>
                <ControllerTextField
                  controllerInstance={control}
                  controllerName="abstract"
                  rows={5}
                  multiline
                  errors={errors}
                  disabled={isSubmitting}
                  marginBottom={'5px'}
                  label={'Title'}
                  placeholder={'The title of your platform'}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} sx={{ marginTop: '20px' }}>
                <ControllerEditorField
                  label={'Description'}
                  placeholder={'Full Platform Description'}
                  controllerName="description"
                  controllerInstance={control}
                  disabled={isSubmitting}
                  errors={errors}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting',
                    },
                  }}
                />
              </Grid>
            </form>
          </Card>
        </div>
      </Box>
    </Page>
  )
}
