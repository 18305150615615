import { httpApi } from './http.api'
import { preview } from '../models/platform/preview.model'
import { ProfileModel } from '../models/profile.model'
import { PlatformProfileModel } from '../models/platform/platform-profile.model'

export interface PageResponse {
  success: boolean
  message: string
  data: {
    account: PlatformProfileModel
    cursor: string
  }
}
export interface PageResponseSingle {
  success: boolean
  message: string
  data: {
    platform: PlatformProfileModel
  }
}

export interface platformRequest {
  logo: string
  cover: string
  name: string
  email: string
  projectVision: string
  projectMission: string
  slogan: string
  overview: string
  website: string
  phone: string
  address: string
  companySize: string
  date: string
  specialties: string
}

export const platformAccount = (): Promise<PageResponse> =>
  httpApi.get<PageResponse>('platform/account').then(({ data }) => data)

export const createPlatform = (
  req: platformRequest
): Promise<PageResponseSingle> =>
  httpApi
    .post<PageResponseSingle>('platform/account/create', req)
    .then(({ data }) => data)

export const updatePlatform = (
  req: platformRequest
): Promise<PageResponseSingle> =>
  httpApi
    .patch<PageResponseSingle>('platform/account/update', req)
    .then(({ data }) => data)
