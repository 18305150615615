import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { preview } from '../../models/platform/preview.model'

const initialState: preview = {
  abstract: 'sgvfd',
  description: 'fdgdfg',
  overview: 'fdgf',
  website: 'fg',
  phone: 'fdg',
  address: 'fdg',
  companySize: '',
  date: new Date(),
  specialties: 'fgg',
}

export const updateAboutSlice = createSlice({
  name: 'updateAbout',
  initialState,
  reducers: {
    set: (state: any, action: PayloadAction<preview>) => {
      return { ...action.payload }
    },
  },
})

export const { set } = updateAboutSlice.actions

export default updateAboutSlice.reducer
