import React, { ReactElement, useEffect } from 'react'
import { Page } from '../../components/structure'
import styled from 'styled-components'
import { LoginButton } from '../../components/auth'
import { RegisterPage } from './register'
import { useAppSelector } from '../../redux/hooks'
import { useNavigate } from 'react-router-dom'

const ProfileBoxStyle = styled.div`
  margin: 0;
  background: ${props => props.theme.navy90};
  border-radius: 8px;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    
  > .header {
    position: relative;

    > img {
      display: block;
      width: 100%;
      height: 200px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  > .title {
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.theme.white100};
    margin: 0 auto 15px auto;
    text-align: center;
  }

  > .desc {
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.white80};
    margin: 0 auto 30px auto;
    text-align: center;
    line-height: 28px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    white-space: pre-line;
  }
`

export function LoginPage(): ReactElement {
  return (
    <Page title="Personia Platform login">
      <div style={{width: '100%', height: '100%', position: 'relative'}}>
      <ProfileBoxStyle>
        <div className={'header'}>
          <img src={require('../../assets/images/wallpaper.png')} alt='wallpaper'/>
        </div>
        <p className={'desc'}>
          Welcome to our decentralized social network! 🎉 We're thrilled to have
          you here! 🤗 This is a community of individuals who value privacy,
          freedom of speech, and building connections. Here, you're not
        </p>
        <div style={{padding: '10px'}}>
          <LoginButton color={'navy25'} textcolor={'white100'} size={'m'} />
        </div>
      </ProfileBoxStyle>
      </div>
    </Page>
  )
}
