import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Card, Page } from '../../components/structure'
import styled from 'styled-components'
import { Banner, Logo, Name, Slogan } from '../../components/account'
import 'cropperjs/dist/cropper.min.css'
import { Grid } from '@mui/material'
import {
  ControllerDaypickerField,
  ControllerEditorField,
  ControllerTextField,
} from '../../components/rhf'
import { CButton } from '../../components/mui'
import { SubmitHandler, useForm } from 'react-hook-form'
import { GlobalContext, useCeramicContext } from '../../contexts'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { MyBlobToBuffer } from '../../utils/file'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { useNavigate } from 'react-router-dom'
import { set } from '../../redux/slices/user'
import { UserBox } from '../../components/user'
import { MySelect } from '../../components/custom/select'
import { preview } from '../../models/platform/preview.model'
import {
  platformAccount,
  platformRequest,
  updatePlatform,
} from '../../apis/platform.apis'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'
import { set as setUpdateAbout } from '../../redux/slices/updateAboutRedux'
import { isDesktop } from '../../utils/detect-screen'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 60px;

    > .flex {
      display: flex;
    }
  }

  > .row-2 {
    position: relative;
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 30px;
    padding: 15px 15px 90px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    > div > .body > p {
      font-family: Inter;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${({ theme }) => theme.white40};
      margin-bottom: 20px;
    }

    > div > .footer {
      position: absolute;
      right: 0;
    }
    .grid-items {
      display: grid;
      grid-template-columns: ${() => (isDesktop() ? '1fr 1fr' : '1fr')};
      grid-template-rows: ${() =>
        isDesktop() ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)'};
      gap: 5px;
      column-gap: 30px;
      align-items: stretch;
    }
  }
`

interface Inputs {
  overview: string
  website: string
  phone: string
  address: string
  companySize: string
  date: Date
  specialties: string
  general: string
}

export function AboutPage(): ReactElement {
  const user = useAppSelector(state => state.user)
  const navigate = useNavigate()
  const userDataPrev = useAppSelector(state => state.userPreview)
  const [queryError, setQueryError] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    setError,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<Inputs>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { makeAlert } = useContext(GlobalContext)
  const [platforms, setPlatforms] = useState<PlatformProfileModel>()
  const [loading, setLoading] = useState<boolean>(true)
  // Initialize IPFS
  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  useEffect(() => {
    setLoading(true)

    platformAccount()
      .then(res => {
        console.log(res.data.account)
        setLoading(false)
        if (res.data.account) {
          setPlatforms(res.data.account)
        }
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
  }, [])

  const companySize = [
    {
      value: 'Company Size',
      text: 'Company Size',
    },
    {
      value: 'Fewer than 10',
      text: 'Fewer than 10',
    },
    {
      value: '10-50',
      text: '10-50',
    },
    {
      value: '51-250',
      text: '51-250',
    },
    {
      value: 'More than 250',
      text: 'More than 250',
    },
  ]

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsSubmitting(true)
    var goOn = true

    if (!data.overview) {
      setError('overview', { message: 'Enter your overview' })
      goOn = false
    }
    if (!data.website) {
      setError('website', { message: 'Enter your website' })
      goOn = false
    }
    if (!data.address) {
      setError('address', { message: 'Enter your phone address' })
      goOn = false
    }
    if (!data.companySize) {
      setError('companySize', { message: 'Enter your company size' })
      goOn = false
    }
    if (!data.date) {
      setError('date', { message: 'Enter the date' })
      goOn = false
    }
    if (!data.specialties) {
      setError('specialties', { message: 'Enter your specialties' })
      goOn = false
    }

    if (!goOn) {
      setIsSubmitting(false)
      return
    }

    try {
      const platformRequest: platformRequest = {
        name: platforms.name,
        slogan: platforms.slogan,
        logo: platforms.logo,
        cover: platforms.cover,
        email: platforms.email,
        projectVision: platforms.projectVision,
        projectMission: platforms.projectMission,
        overview: data.overview,
        website: data.website,
        phone: data.phone,
        address: data.address,
        companySize: data.companySize,
        date: new Date(data.date)
          .toLocaleDateString('en-CA')
          .replace(/\//g, '-'),
        specialties: data.specialties,
      }

      await updatePlatform(platformRequest)
        .then(result => {
          navigate('/')
        })
        .catch(error => {
        })
    } catch (error) {
      setError('general', {
        message: 'An error occurred while processing the form',
      })
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      setValue('overview', decodeURIComponent(platforms?.overview ?? ''), {
        shouldValidate: true,
      })
      setValue('website', platforms?.website ?? '', {
        shouldValidate: true,
      })
      setValue('phone', platforms?.phoneNumber ?? '', {
        shouldValidate: true,
      })
      setValue(
        'date',
        platforms?.fundingStartDate
          ? new Date(platforms.fundingStartDate)
          : undefined,
        {
          shouldValidate: false,
        }
      )
      setValue('specialties', platforms?.specialties ?? '', {
        shouldValidate: true,
      })
      setValue('address', platforms?.address ?? '', {
        shouldValidate: true,
      })
      setQueryError(false)
    })()
  }, [setValue, platforms])

  useEffect(() => {
    if (platforms?.companySize === '' || platforms?.companySize === undefined) {
      setValue('companySize', '', { shouldValidate: false })
    } else {
      setValue('companySize', platforms.companySize, {
        shouldValidate: true,
      })
    }
  }, [setValue, platforms])

  return (
    <Page title="About">
      <Box>
        <div className={'row-2'}>
          <Card
            title={'Platform Information & Contact Details'}
            footer={[
              <CButton
                key={1}
                loading={isSubmitting}
                disabled={isSubmitting}
                margin={isDesktop() ? '0 15px 0 0' : '0px'}
                form={'create-platform'}
                background={'navy25'}
                backgroundHover={'navy25'}
                color={'white100'}
                type={'submit'}
                onClick={handleSubmit(onSubmit)}
                style={{
                  width: isDesktop() ? '172px' : '120px',
                  height: '45px',
                }}
              >
                Save
              </CButton>,
            ]}
          >
            <p className={'platform-info'}>
              Provide key details about your business to help others learn more
              about you. Make it easy for potential customers and partners to
              get in touch.
            </p>

            <form
              className="form"
              id="create-platform"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid item md={12} sm={12}>
                <ControllerTextField
                  controllerInstance={control}
                  controllerName="overview"
                  rows={5}
                  multiline
                  errors={errors}
                  disabled={isSubmitting}
                  marginBottom={'5px'}
                  label={'Overview'}
                  placeholder={'Overview of your platform'}
                  controllerRules={{
                    minLength: {
                      value: 10,
                      message: 'Please provide at least 10 characters',
                    },
                    maxLength: {
                      value: 1000,
                      message: 'Please edit to 1000 characters or fewer',
                    },
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} sx={{ marginTop: '20px' }}>
                <div className="grid-items">
                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="website"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Website'}
                      placeholder={'Enter your website'}
                      controllerRules={{
                        maxLength: {
                          value: '100',
                          message: 'Please edit to 100 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="phone"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Phone'}
                      placeholder={'Enter your phone number'}
                      controllerRules={{
                        minLength: {
                          value: 10,
                          message: 'Please provide at least 10 characters',
                        },
                        maxLength: {
                          value: 1000,
                          message: 'Please edit to 1000 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="address"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Address'}
                      placeholder={'Enter your address'}
                      controllerRules={{
                        maxLength: {
                          value: 400,
                          message: 'Please edit to 400 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <MySelect
                      name={'companySize'}
                      label={''}
                      options={companySize}
                      errors={errors}
                      controllerName={'companySize'}
                      controllerInstance={control}
                      controllerRules={{
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div
                    className="controllerInput"
                    style={{ marginBottom: '10px' }}
                  >
                    <ControllerDaypickerField
                      label={''}
                      controllerName={'date'}
                      controllerInstance={control}
                      errors={errors}
                      controllerRules={{
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>

                  <div className="controllerInput">
                    <ControllerTextField
                      controllerInstance={control}
                      controllerName="specialties"
                      rows={1}
                      multiline
                      errors={errors}
                      disabled={isSubmitting}
                      marginBottom={'5px'}
                      label={'Specialties'}
                      placeholder={'Enter your specialties'}
                      controllerRules={{
                        maxLength: {
                          value: 500,
                          message: 'Please edit to 500 characters or fewer',
                        },
                        required: {
                          value: true,
                          message:
                            'Please ensure all required fields, indicated with (*), are filled before submitting.',
                        },
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </form>
          </Card>
        </div>
      </Box>
    </Page>
  )
}
