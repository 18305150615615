import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { registerPlatformJourney } from '../../models/platform/registerPlatformInfo';
import { preview } from '../../models/platform/preview.model';

const initialState: preview = {
  id: '',
  name: '',
  banner: null,
  logo: null,
  slogan: '',
  abstract: '',
  description: '',
  overview: '',
  website: '',
  phone: '',
  address: '',
  companySize: '',
  date: '',
  specialties: '',
  did: '',
}

export const userPreviewSlice = createSlice({
  name: 'user-preview',
  initialState,
  reducers: {
    set: (state: any, action: PayloadAction<preview>) => {
      return { ...action.payload }
    },
    update: (state: any, action: PayloadAction<Partial<preview>>) => {
      return { ...state, ...action.payload }
    },
    logout: (state: any) => {
      return { ...state, ...initialState }
    },
  },
})

export const { set, update, logout } = userPreviewSlice.actions

export default userPreviewSlice.reducer;
