import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model'
import { registerPlatformJourney } from '../../../models/platform/registerPlatformInfo'
import { preview } from '../../../models/platform/preview.model'
import { FieldError } from 'react-hook-form'
import { breakpoints } from '../../../config'

const BannerStyle = styled.div<{ height: string, borderColor: string }>`
    height: 202px !important;
    background: ${({ theme }) => theme.gray100};
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
 @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
  height: 110px !important;
  p{
    font-size: 14px !important;
    font-weight: 500;
    padding-top: 35px;
  }}
    > p {
        font-size: 24px;
        font-weight: 600;
        font-family: Inter;
        color: ${({ theme }) => theme.white100};
        line-height: 32px;
        text-align: center;
        width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    > img {
        width: 100%;
        height: ${({ height }) => height};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`

export interface Props {
  platform: preview,
  height?:string,
  error?: FieldError
}

export function Banner(props: Props) {
  const { platform, height='202',error } = props
  const [selectedCover, setSelectedCover] = useState<File>(null)

  useEffect(()=>{
    setSelectedCover(platform?.banner)
    console.log(platform)
  },[platform])

  return (
    <>
      <BannerStyle borderColor={error ? 'red100' : 'black100'} height={height}>
      {platform.banner != null  && (
          // <img loading={"lazy"} src={`https://greenia.infura-ipfs.io/ipfs/${platform.logo}`}  alt={''}/>
          <img src={URL.createObjectURL(platform.banner)} alt='banner' loading={'lazy'}/>
        )}
      </BannerStyle>
    </>
  )
}
//