import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

const Box = styled.div<{ $marginBottom: string; $footerAlignment: string }>`
  background: ${props => props.theme.navy80};
  border-radius: 8px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  width: 96%;

  > .header {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > h5 {
      color: ${props => props.theme.white100};
      flex-grow: 1;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    > .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  > .body {
    padding: 15px;

    & .border {
      width: 100%;
      height: 1px;
      background: ${props => props.theme.white100};
    }
  }

  > .footer {
    display: flex;
    flex-direction: ${({ $footerAlignment }) => $footerAlignment};
    align-items: center;
    padding: 15px;

    > .item {
      margin: 5px;
    }
  }
`

interface Props {
  title?: string
  children: ReactNode
  footer?: ReactNode[]
  actions?: ReactNode[]
  marginBottom?: string
  footerAlignment?: string
  style?: any
}

export function Card(props: Props): ReactElement {
  const {
    title,
    children,
    footer,
    actions,
    marginBottom = '0',
    footerAlignment = 'row',
    style
  } = props

  return (
    <>
      <Box style={style} $marginBottom={marginBottom} $footerAlignment={footerAlignment}>
        {title != null || (actions != null && actions.length > 0) ? (
          <div className={'header'}>
            <h5>{title}</h5>

            <div className={'actions'}>
              {actions?.map((item, i) => (
                <div className={'item'} key={i}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        ) : null}

        <div className={'body'}>{children}</div>

        {footer != null && footer.length > 0 ? (
          <div className={'footer'}>
            {footer?.map((item, i) => (
              <div className={'item'} key={i}>
                {item}
              </div>
            ))}
          </div>
        ) : null}
      </Box>
    </>
  )
}
