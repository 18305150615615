import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../redux/hooks'
import { CropperJs } from '../custom/cropper'
import { CModal } from '../mui'
import { DropZone } from '../custom'
import { FieldError } from 'react-hook-form'
import { createPlatform, platformAccount, platformRequest } from '../../apis/platform.apis'
import { create, IPFSHTTPClient } from 'ipfs-http-client'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'
import { breakpoints } from '../../config'
import { isDesktop } from '../../utils/detect-screen'

const BannerStyle = styled.div<{ borderColor: string }>`
  height: 202px;
  background: ${({ theme }) => theme.gray100};
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid ${({ theme, borderColor }) => theme[borderColor]};
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
  height: 110px;
  p{
    font-size: 14px !important;
    font-weight: 500;
    padding-top: 35px;
  }
}

  > p {
    font-size: 24px;
    font-weight: 600;
    font-family: Inter;
    color: ${({ theme }) => theme.white100};
    line-height: 32px;
    text-align: center;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  > img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`

export interface Props {
  isEditing: boolean
  banner: Blob
  setBanner: (banner: Blob) => void
  errors: FieldError
  handleCoverPreview: (e: File) => void
  ipfsbanner?: (ipfsbanner) => void
}

export function Banner(props: Props) {
  const {
    isEditing,
    banner,
    setBanner,
    errors,
    handleCoverPreview,
    ipfsbanner,
  } = props

  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = create({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }
  const _user = useAppSelector(state => state.user)
  const user = useAppSelector(state => state.userPreview)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [bannerFile, setBannerFile] = useState<File>(null)
  const [selectedBanner, setSelectedBanner] = useState<File | null>(null)
  const [ipfsBanner, setIpfsBanner] = useState(null)
  const [platformData, setPlatformData] = useState<PlatformProfileModel>()

useEffect(() => {
  setLoading(true)

  platformAccount()
    .then(res => {
      setLoading(false)

      if (res?.data?.account) {
        setPlatformData(res.data.account)
      } else {
        console.log('No account data found')
      }
    })
    .catch(error => {
      setLoading(false)
    })
}, [])


  useEffect(() => {
    if (isEditing && user.banner && !selectedBanner) {
      setSelectedBanner(new File([user.banner], '', { type: user.banner.type }))
    }
  }, [isEditing, selectedBanner, user])

  const onCropBanner = async (blob: Blob | null) => {
    setBanner(blob)
    setBannerFile(null)
    setSelectedBanner(new File([blob], '', { type: blob.type }))

    if (bannerFile) {
      handleCoverPreview(bannerFile)
    }

    if (blob != null && ipfs != null) {
      const ipfsResult = await ipfs?.add(blob)
      setIpfsBanner(ipfsResult)
      ipfsbanner(ipfsBanner)
      console.log(ipfsBanner)
    }
  }

  const handleClickBanner = () => {
    setOpenModal(true)
  }

  const handleFileSelect = (files: File[]) => {
    const file = files[0]
    const maxSize = 5 * 1024 * 1024

    if (file.size > maxSize) {
      alert(
        'The image you uploaded exceeds the 5 MB limit. Please upload an image that is smaller'
      )
      return
    }
    setBannerFile(file)

  }

  return (
    <>
      <BannerStyle borderColor={errors ? 'red100' : 'transparent'}>
        {(_user.did === '' || isEditing) && (
          <p onClick={handleClickBanner}>
            Add a banner picture for your platform here +
          </p>
        )}
        {selectedBanner && (
          <img src={URL.createObjectURL(selectedBanner)} alt="Banner" />
        )}
        {!selectedBanner && platformData?.cover != null && (
          <img
            src={`https://greenia.infura-ipfs.io/ipfs/${platformData?.cover}`}
            alt="Banner"
          />
        )}
      </BannerStyle>

      <CModal
        open={openModal}
        onClose={() => {
          setBanner(null)
          setOpenModal(false)
        }}
        title=""
        background={'navy90'}
      >

        {bannerFile ? (
          <CropperJs
            src={URL.createObjectURL(bannerFile)}
            onCrop={blob => {
              setOpenModal(false)
              onCropBanner(blob)
            }}
            aspectRatio={1440 / 202}
            selectedFile={bannerFile}
            setSelectedFile={item => {
              setBannerFile(item)
            }}
            aspectRatioEditable={false}
            submitLoading={loading}
          />
        ) : (
          <DropZone
            label={'Banner'}
            disabled={loading}
            acceptedFiles={[
              'image/jpeg',
              'image/jpg',
              'image/png',
              'image/gif',
            ]}
            selectedFiles={[]}
            type={'avatar'}
            onSelect={handleFileSelect}
              />
        )}
      </CModal>
    </>
  )
}
