import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

import { ProfileModel } from '../../models/profile.model'

const initialState: ProfileModel = {
  email: '',
  creator: {
    id: ''
  },
  id: '',
  name: '',
  avatar: '',
  nakamaID: '',
  bio: '',
  postsCount: 0,
  followersCount: 0,
  followingsCount: 0,
  did: '',
  cover: '',
  accountType: '',
  age: 0,
  skills: [],
  gender: '',
  phoneNumber: '',
  address: '',
  socialLinks: [],
  publicEncryptionDID: { id: '' },
  experiences: [],
  educations: [],
  posts: [],
  isFollowed: null,
  followers: [],
  followings: [],
  profile: {},
  targetProfile: {},
  wallet: '', 
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state: any, action: PayloadAction<ProfileModel>) => {
      return { ...action.payload }
    },
    update: (
      state: any,
      action: PayloadAction<Partial<ProfileModel>>
    ) => {
      return { ...state, ...action.payload }
    },
    logout: (state: any) => {
      return { ...state, ...initialState }
    },
  },
})

export const { set, update, logout } = userSlice.actions

export default userSlice.reducer
