import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
import styled from 'styled-components'
import '../../assets/editor.css'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { CButton, CSkeleton, CTab, CTabs } from '../../components/mui'
import { Banner, Logo, Name, Slogan } from '../../components/platform/header'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model'
import { PlatformData } from '../../components/platform/header/platformData'
import { isDesktop } from '../../utils/detect-screen'
import { breakpoints } from '../../config'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { SubmitHandler, useForm } from 'react-hook-form'
import { preview } from '../../models/platform/preview.model'
import { MyBlobToBuffer } from '../../utils/file'
import { createPlatform, platformRequest } from '../../apis/platform.apis'
import user, {
  set as setuserPreview,
  userPreviewSlice,
} from '../../redux/slices/user-preview'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 8px;
    border: 0.5px solid #888282;
    top: 60px;
    > .flex {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      > .profile {
      }
      > .wrap {
        width: 100%;
      }
      > .right {
        position: absolute;
        right: 20px;
        top: 20px;
        float: right;
        vertical-align: center;
      }
    }
  }

  > .row-2 {
    > .body {
      padding: 20px;
      background: ${props => props.theme.navy80};

      > .text {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 22px;

        > div:last-child {
          position: relative;
        }

        > .editor {
          > div:first-of-type {
            > span {
              font-size: 38px;
              font-weight: 600;
              line-height: 46px;
              font-style: normal;
            }
          }
          .ql-editor {
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
          }
        }

        p,
        span,
        div {
          color: ${props => props.theme.white100};
        }

        > h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        li,
        ol {
          color: ${props => props.theme.white100};
        }

        > a {
          color: ${props => props.theme.green100};
        }

        > img {
          margin-top: 20px;
        }
        > div:nth-of-type(1) {
          font-size: 38px;
          font-weight: 600;
          line-height: 46px;
          font-style: normal;
          @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
            font-size: 20px;
          }
        }
        > p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        > div {
          > * {
            display: flex;
            width: 100%;
            height: auto;
            align-self: stretch;
            margin: -0.1rem 0;
          }
          > span:nth-of-type(1) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
          > a {
            color: #1677ff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
          > span:nth-of-type(2) {
            color: rgba(255, 255, 255, 0.65);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
`

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
  background: ${props => props.theme.navy80};
  border-radius: 16px;

  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

interface Inputs {
  description: string
  abstract: string
  cover: Blob
  logo: Blob
  name: string
  slogan: string
  platformID: string
  overview: string
  phone: string
  address: string
  website: string
  companySize: string
  specialties: string
  date: Date
  projectVision: string
  projectMission: string
  email: string
  general: string
  startups: preview[]
  user: preview
}

export function PlatformDetailPage(): ReactElement {
  const user = useAppSelector(state => state.userPreview)
  const {
    control,
    handleSubmit,
    setError,
    getFieldState,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm<Inputs>({})

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  const { id } = useParams()
  const [platform, setPlatform] = useState<PlatformProfileModel>()
  const [platformLoading, setPlatformLoading] = useState<boolean>(false)
  const [platformModel, setPlatformModel] = useState<PlatformProfileModel>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [queryError, setQueryError] = useState<boolean>(false)
  const navigate = useNavigate()
  const [logo, setLogo] = useState<File | null>(null)
  const [banner, setBanner] = useState<File | null>(null)
  const [tab, setTab] = useState<'posts' | 'articles' | 'journey' | 'about'>(
    'journey'
  )

  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  const handleChangeViewType = (event: any, newValue: any): void => {
    setTab(newValue)
  }

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsSubmitting(true)
    let goOn = true

    if (!user.logo) {
      setError('logo', { message: 'Select logo' })
      goOn = false
    }
    if (!user.banner) {
      setError('cover', { message: 'Select banner' })
      goOn = false
    }
    if (!user.name) {
      setError('name', { message: 'Enter name of company' })
      goOn = false
    }
    if (!user.slogan) {
      setError('slogan', { message: 'Enter slogan of company' })
      goOn = false
    }
    if (!user.abstract) {
      setError('abstract', { message: 'Enter abstract' })
      goOn = false
    }
    if (!user.description) {
      setError('description', { message: 'Enter description' })
      goOn = false
    }
    if (!user.overview) {
      setError('overview', { message: 'Enter overview' })
      goOn = false
    }
    if (!user.address) {
      setError('address', { message: 'Enter address' })
      goOn = false
    }
    if (!user.phone) {
      setError('phone', { message: 'Enter phone' })
      goOn = false
    }
    if (!user.website) {
      setError('website', { message: 'Enter website' })
      goOn = false
    }
    if (!user.date) {
      setError('date', { message: 'Enter date' })
      goOn = false
    }
    if (!user.companySize) {
      setError('companySize', { message: 'Enter companySize' })
      goOn = false
    }
    if (!user.specialties) {
      setError('specialties', { message: 'Enter specialties' })
      goOn = false
    }

    if (!goOn) {
      setIsSubmitting(false)
      return
    }

    let bannerFile: any = null
    let logoFile: any = null

    const uploadToIPFS = (file: Blob) =>
      new Promise((resolve, reject) => {
        MyBlobToBuffer(file, async (err, buff) => {
          if (err) {
            reject(err)
          } else if (buff != null) {
            try {
              const result = await ipfs?.add(buff)
              resolve(result)
            } catch (uploadErr) {
              reject(uploadErr)
            }
          }
        })
      })

    try {
      if (user.banner) {
        bannerFile = await uploadToIPFS(user.banner)
        console.log('Banner File Hash:', bannerFile?.path)
      }

      if (user.logo) {
        logoFile = await uploadToIPFS(user.logo)
        console.log('Logo File Hash:', logoFile?.path)
      }
      const platformRequest: platformRequest = {
        name: user.name,
        email: '',
        projectVision: user.abstract,
        projectMission: user.description,
        slogan: user.slogan,
        overview: user.overview,
        website: user.website,
        phone: user.phone,
        address: user.address,
        companySize: user.companySize.toString(),
        date: new Date(user.date)
          .toLocaleDateString('en-CA')
          .replace(/\//g, '-'),
        specialties: user.specialties,
        // @ts-ignore
        cover: bannerFile?.path,
        // @ts-ignore
        logo: logoFile?.path,
      }

      await createPlatform(platformRequest)
        .then(result => {
          navigate('/')
        })
        .catch(error => {
        })
    } catch (error) {
      setError('general', {
        message: 'An error occurred while processing the form',
      })
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Page title="Startup">
      <Box>
        {platformLoading ? (
          <>
            <div className={'row-1'}>
              <CSkeleton width={'100%'} height={'202px'} borderradius={'8px'} />
              <div className={'flex'}>
                <CSkeleton
                  width={'162px'}
                  height={'162px'}
                  borderradius={'1000px'}
                  marginTop={'-80px'}
                  marginleft={'16px'}
                />
                <div>
                  <CSkeleton
                    width={200}
                    height={10}
                    marginBottom={'5px'}
                    marginleft={'16px'}
                    marginTop={'20px'}
                  />
                  <CSkeleton
                    width={200}
                    height={10}
                    marginBottom={'5px'}
                    marginleft={'16px'}
                  />
                </div>
              </div>
            </div>
            <div className={'row-2'}>
              <TabBox>
                <CTabs
                  value={tab}
                  key={1}
                  $padding={'20px'}
                  $background={'navy60'}
                  $activeBG={'navy60'}
                >
                  <CTab
                    label={'Journey'}
                    id={'view-tab-journey'}
                    aria-controls={'view-tabpanel-journey'}
                    value={'journey'}
                    disableTouchRipple
                    $fullWidth
                  />
                  <CTab
                    label={'About'}
                    id={'view-tab-about'}
                    aria-controls={'view-tabpanel-about'}
                    value={'about'}
                    $fullWidth
                  />
                </CTabs>
              </TabBox>
              <div className={'body'}>
                {tab === 'journey' && (
                  <CSkeleton
                    width={'90%'}
                    height={10}
                    marginBottom={'20px'}
                    marginleft={'16px'}
                    marginTop={'20px'}
                    marginright={'16px'}
                  />
                )}
                {tab === 'about' && (
                  <CSkeleton
                    width={'90%'}
                    height={10}
                    marginBottom={'20px'}
                    marginleft={'16px'}
                    marginTop={'20px'}
                    marginright={'16px'}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={'row-1'}>
              <Banner
                height={isDesktop() ? '202px' : '110px'}
                platform={user}
              />
              <div className={'flex'}>
                <Logo platform={user} />
                <div
                  style={
                    isDesktop()
                      ? {
                          padding: '20px',
                          marginBottom: '10px',
                          marginLeft: '20px',
                        }
                      : {
                          padding: '0px',
                          marginBottom: '0px',
                          marginLeft: '0px',
                          textAlign: 'center',
                          width: '100%',
                          marginTop: '-15px',
                        }
                  }
                >
                  <Name platform={user} />
                  <Slogan platform={user} />
                </div>
              </div>
            </div>
            <div className={'row-2'}>
              <TabBox>
                <CTabs
                  value={tab}
                  key={1}
                  $padding={'20px'}
                  $background={'navy60'}
                  $activeBG={'navy60'}
                  onChange={handleChangeViewType}
                >
                  <CTab
                    label={'Journey'}
                    id={'view-tab-journey'}
                    aria-controls={'view-tabpanel-journey'}
                    value={'journey'}
                    disableTouchRipple
                    $fullWidth
                  />
                  <CTab
                    label={'About'}
                    id={'view-tab-about'}
                    aria-controls={'view-tabpanel-about'}
                    value={'about'}
                    disableTouchRipple
                    $fullWidth
                  />
                </CTabs>
              </TabBox>

              <div className={'body'}>
                {tab === 'journey' && (
                  <div className={'text'}>
                    <div className={'editor'}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >

                        <div>abstract</div>
                        <div>
                          <CButton
                            key={1}
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            margin="0 15px 0 0"
                            form={'create-startup'}
                            background={'gray80'}
                            backgroundHover={'gray80'}
                            color={'white100'}
                            type={'submit'}
                            onClick={() => navigate('/auth/register')}
                            style={{
                              width: '172px',
                              height: '45px',
                            }}
                          >
                            Edit
                          </CButton>
                          <CButton
                            key={1}
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            margin="0 15px 0 0"
                            form={'create-startup'}
                            background={'navy25'}
                            backgroundHover={'navy25'}
                            color={'white100'}
                            type={'submit'}
                            onClick={handleSubmit(onSubmit)}
                            style={{
                              width: '172px',
                              height: '45px',
                            }}
                          >
                            Save
                          </CButton>
                        </div>
                      </div>
                      <div
                        className={'ql-container ql-snow'}
                        style={{
                          height: 'auto',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          className={'ql-editor'}
                          dangerouslySetInnerHTML={{
                            __html: decodeURIComponent(user.description),
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                {tab === 'about' && (
                  <div className="text">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center  ',
                      }}
                    >
                      <div>Overview</div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center  ',
                        }}
                      >
                        <CButton
                          key={1}
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          margin="0 15px 0 0"
                          form={'create-startup'}
                          background={'gray80'}
                          backgroundHover={'gray80'}
                          color={'white100'}
                          type={'submit'}
                          onClick={() => navigate('/auth/register')}
                          style={{
                            width: '172px',
                            height: '45px',
                          }}
                        >
                          Edit
                        </CButton>
                        <CButton
                          key={1}
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          margin="0 15px 0 0"
                          form={'create-startup'}
                          background={'navy25'}
                          backgroundHover={'navy25'}
                          color={'white100'}
                          type={'submit'}
                          onClick={handleSubmit(onSubmit)}
                          style={{
                            width: '172px',
                            height: '45px',
                          }}
                        >
                          Save
                        </CButton>
                      </div>
                    </div>
                    <div>{user.overview}</div>
                    <div>
                      <span>Website</span>
                      <a href="https://embodia.io/">{user.website}</a>
                    </div>
                    <div>
                      <span>Phone Number</span>
                      <a href="tel:+(307) 555-0133">{user.phone}</a>
                    </div>
                    <div>
                      <span>Address</span>
                      <span>{user.address}</span>
                    </div>
                    <div>
                      <span>Company Size</span>
                      <span>{user.companySize}</span>
                    </div>
                    <div>
                      <span>Founded</span>
                      <span>
                        {user.date
                          ? new Date(user.date)
                              .toLocaleDateString('en-CA')
                              .replace(/\//g, '-')
                          : 'No date available'}
                      </span>
                    </div>
                    <div>
                      <span>Specialties</span>
                      <span>{user.specialties}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Box>
    </Page>
  )
}
